<template>
    <div>
        <UseraccountDetailsVue></UseraccountDetailsVue>
    </div>
</template>
<script>
import UseraccountDetailsVue from '@/components/Dashboard/UseraccountDetails.vue';

export default {
  name: 'App',
  components: {
    UseraccountDetailsVue
  }}
</script>