<template>
  <div class="h-full bg-teal-50">
        <div class="flex items-center  lg:-mt-10  px-5 " >
          <div class=" mt-12 pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Community Status</h1>
            </div>
            <div class="px-3">
            <p class="text-sm p text-red-700"> <i>{{messageText}}</i></p>
            </div>
          </div>
        </div>
      </div>

      <div class="h-full  bg-teal-50">
        <div class="flex items-center  lg:-mt-10  px-5  p-12" >
          <div class=" mt-12 pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Community Domain Info </h1>
            </div>
            <div class="px-3">
              <p class="text-sm p text-teal-700">Domain : <b class="text-red-700">{{ domain }} </b></p>
              <p class="text-sm p text-teal-700 mt-2">Nameserver1 : <b class="text-red-700">{{ nameserver1 }} </b></p>
              <p class="text-sm p text-teal-700 mt-2">Nameserver2 : <b class="text-red-700">{{ nameserver2 }} </b></p>
          </div>
           <div class="mb-4 mt-4 ml-2">
              <button
                class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                >
              Request for Update
              </button>
            </div>
          </div>
        </div>
      </div>


      <div class="h-full bg-teal-50 mb-10">
        <!-- component -->
        <div class="flex items-center  lg:-mt-10  px-5  p-12" >
          <!-- Author: FormBold Team -->
          <div class="mt-12  lg:w-4/5  bg-white font-sans" >
            <div class="mt-12">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Dating Community</h1>
            </div>
            <div class="w-full mt-4 px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Community Name
                </label>
                <input
                  type="text"
                  v-model="name"
                  placeholder="Community Name"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
              </div>
              <div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                <span v-if="v$.name.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Description
                </label>
                <textarea
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="Community Description"
                  v-model="description"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  ></textarea>
              </div>
              <div class="input-errors" v-for="error of v$.description.$errors" :key="error.$uid">
                <span v-if="v$.description.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div class="mb-4 ml-2">
              <button
                class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                @click="updateCommunity"
                >
              Update basic info
              </button>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
  import { useVuelidate } from '@vuelidate/core'
  import { required , minLength } from '@vuelidate/validators'
  export default {
  setup () {
       return { v$: useVuelidate() }
     },
  validations () {
     return {
       
        name: { required },
        description: { required },
      }
   },
  data() {
    return {
      name:"",
      description:"",
      domain:"",
      nameserver1:"",
      nameserver2:"",
      acknowledged:false,
      status:false
    }
  },
  methods: {
    async updateCommunity() {
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect) {
        return this.$notify({ title:"Error",type: "error", text: "Please fillout all the required fields" }); 
      }
      try {
        let activeAccountId = this.$store.state.core.activeAccount.id
        await this.axios.put(`user/dating-community/${activeAccountId}`, {
          name:this.name,
          description:this.description
        },{
          loadingMesssage: "Loading"
        })
        this.$notify({ title:"Message",type: "success", text: "Data Updated Successfully" });
      }catch(e){
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    }
  },
  computed:{
    messageText() {
      console.log(this.status,"stst")
      console.log(this.acknowledged,"acknowledged")

      if(!this.status && !this.acknowledged) {
          return 'You dating community is successfully registered and sent for the review, you will receive the email when the status is complete. Thanks for choosing MAZU community'
      }
      if(this.status == false && this.acknowledged == true) {
        return 'Your community is under construction'
      }
      if(this.status == true && this.acknowledged == true) {
        return 'Your community is running successfully'
      }
    }
  },
  async created() {
    let activeAccountId = this.$store.state.core.activeAccount.id
      let { data } = await this.axios.get(`user/dating-community/${activeAccountId}`, {
        loadingMesssage: "Loading"
      })
      let communityData = data.data
      if(!communityData) {
        this.$router.push('/dashboard')
      }
      this.name = communityData.name
      this.description = communityData.description
      this.domain = communityData.domain
      this.nameserver1 = communityData.nameserver1
      this.nameserver2 = communityData.nameserver2
      this.acknowledged = communityData.acknowledged
      this.status = communityData.status
    }
  }
</script>