<template>
  <li v-if="show" class="">
    <router-link :to="link"><a href="#"
      class="relative flex bg-teal-500 btn flex-row items-left h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700"
      :class="{ 'bg-red-800 hover:bg-black':$store.state.core.userData.role == 'MAZU_DEVELOPER' }"
      >
      <span class="inline-flex justify-left items-left ml-4">
        <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
          </path>
        </svg>


      </span><span class="ml-2 text-xs tracking-wide truncate text-white">{{ text }}</span>
    </a></router-link>
  </li>
</template>


<script>
export default {
  props:['link','text','adminType'],
  computed:{
    show(){
      let adminUserRole = this.$store.state.core.userData.role
      let adminTypes =  this.adminType.split(",")
      if(!adminTypes.includes(adminUserRole)) return false
      return true 
    }
  }
}
</script>