<template>
  <div>
    <div
    
      class="fixed  flex flex-col top-14 left-0 w-14 hover:w-64 md:w-64 bg-teal-800 dark:bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar">
      <div class="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
        <ul class="flex flex-col py-4 space-y-1">
          <li class="">
            <router-link to="/"><a href="#"
              class="relative flex bg-teal-500 btn flex-row items-left h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-left items-left ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Home</span>
            </a></router-link>
            </li>
             <li class="">
            <router-link to="/dashboard/accounts"><a href="#"
              class="relative flex bg-teal-500 btn flex-row items-left h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-left items-left ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Mazu Accounts</span>
            </a></router-link>
            </li>
          <li>
            <router-link to="/dashboard"><a href="#"
              class="relative flex bg-teal-500 btn flex-row items-left h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-left items-left ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Dashboard</span>
            </a></router-link>
          </li>
           <!-- <li class="">
            <router-link to="/dashboard/basicinfo"><a href="#"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Basic Informations</span>
            </a></router-link>
          </li> -->
           <li class="">
            <router-link to="/dashboard/shop"><a href="#"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Shop</span>
            </a></router-link>
          </li>
          <li class="">
            <router-link to="/dashboard/products"><a href="#"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Products</span>
            </a></router-link>
          </li>
          <li class="">
            <a href="#" @click="logOut"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Logout</span>
            </a>
          </li>
          <!-- <li class="">
            <a href="#"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">User Basic Informations</span>
            </a>
          </li>

          <li class="">
            <a href="#"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">User Basic Informations</span>
            </a>
          </li>

          <li class="">
            <a href="#"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700">
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>


              </span><span class="ml-2 text-xs tracking-wide truncate text-white">User Basic Informations</span>
            </a>
          </li> -->







        </ul>
        <!-- <p class="mb-14 px-5 py-3 hidden md:block text-center text-xs">Copyright @2021</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import helperMixin from '@/mixins/helper'
 export default {
   mixins:[helperMixin],
    methods:{
      logOut(){
       this.logOutUser()
       this.$router.push('/')
      }
    }
  }
</script>