<template>
  <MazushopComponent
  :loggedIn="true"
  :activeProducts="activeProducts"
  />
</template>

<script>
import MazushopComponent from '@/components/Shop/MazushopComponent.vue'
export default {
  components:{
    MazushopComponent
  },
  data(){
    return {
      activeProducts:[]
    }
  },
 async created() {
     try {
      let { data } = await this.axios.get(`user/accounts/products/${this.$store.state.core.activeAccount.id}`)
     let allProducts = data.data
     let activeExtensions = allProducts.Extensions.map(e => e.id)
     let activePlugins = allProducts.Plugins.map(p => p.id)
     let activeThemes = allProducts.Themes.map(t => t.id)

     let activeProducts = [ ... activeExtensions , ...activePlugins , ...activeThemes];
     this.activeProducts = activeProducts

    }catch(e) {
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: this.error });
    }
    // http://localhost:8082/api/user/accounts/products/dec09a31-1f03-49eb-a223-de69f7972d69
  }
}
</script>