import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";




const core = {
  state: {
      userData:{},
      loggedIn:false,
      adminloggedIn:false,
      token:null,
      loading:false,
      loadingText:"MAZU Loading"
    },
    mutations: {
      userData(state,data) {
        state.userData = data
      },
      userAccounts(state,data){
        state.userAccounts = data
      },
      activeAccount(state,data){
        state.activeAccount = data
      },
      loggedIn(state,data) {
        state.loggedIn = data
      },
      adminloggedIn(state,data) {
        state.adminloggedIn = data
      },
      token(state,data) {
        state.token = data
      },
      loading(state,data) {
        state.loading = data
      },
      loadingText(state,data) {
        state.loadingText = data
      },
      
      
    },
    actions: {

    },
    getters: {
      userData(state){
        return state.userData
      },
      userAccounts(state){
        return state.userAccounts
      },
      activeAccount(state){
        return state.activeAccount
      },
      loggedIn(state) {
        return state.loggedIn
      },
      adminloggedIn(state) {
        return state.adminloggedIn
      },
      token(state) {
        return state.token
      },
      loading(state) {
        return state.loading 
      },
      loadingText(state) {
        return state.loadingText
      },
    }
 }

const store = createStore({
  modules: {
    core: core,
  },
  plugins: [createPersistedState({
    paths: ["core.userData","core.userAccounts","core.activeAccount","core.loggedIn","core.adminloggedIn","core.token"],
  })],
})


 export default store