<template>
    <AddProduct
    productType = 'extension'
    />
</template>

<script>
import  AddProduct  from "@/components/Admin/AddProduct.vue"
export default {
  components:{
    AddProduct
  }
}
</script>