<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">  Update DB url</span>
        </div>
      </div>
      |   
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="flex flex-col justify-top">
            <div class="max-w-xl mb-6">
              <h2
                class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Update DB url
              </h2>
            </div>
            <div class="mx-auto  lg:w-4/5  bg-white font-sans" >
              <div class=" px-5 mt-12 mb-12" >
                <div class="mb-3 block text-base font-medium text-teal-500 font-sans"></div>
                <div class="-mx-3 flex flex-wrap">
                  <div class=" px-3 sm:w-full">
                    <div class="mb-5 ">
                      <label
                        for="fName"
                        class="mb-3 block text-base font-medium text-teal-500 font-sans"
                        >
                      Db url
                      </label>
                      <input
                        type="text"
                        v-model="database_url"
                        placeholder ="Database url"
                        class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                    </div>
                  </div>
                </div>
                <div class=" px-3 sm:w-4/2">
                  <div class="mt-8">
                    <button 
                      @click="update"
                      class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"> Update/Add </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    async created() {
      try {
        let { data } = await this.axios.get(`admin/backend/dating-community/${this.$route.params.id}`, {
          loadingMesssage: "Loading"
        })
        this.database_url = data.datingCommunity.database_url
      }catch(e){
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    },
    data() { 
      return {
       database_url:""
      }
    },
    methods: {
      async update() {
        try{
          await this.axios.patch(`admin/backend/dating-community/db/${this.$route.params.id}`,{
            dbUrl:this.database_url
          },{
            loadingMesssage:"Updating"
          })
          this.$notify({ title:"Message",type: "success", text: "Db updated Successfully" });
          this.$router.push('/admin/dating-communities')
         } catch(e){
          console.log(e)
          let { data : { message } } = e.response
          this.error = message
          this.$notify({ title: "Error", text: this.error });
        }
      }
    }
  }
</script>