z<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-white md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <!-- <img class="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg" /> -->
          <span class=" text-gray-700 font-bold  text-3xl">Shop By</span>
        </div>
      </div>
      <!-- ./Header -->
      <!-- Sidebar -->
      <ShopSidebar @filtered="filterShop"></ShopSidebar>
      <!-- ./Sidebar -->
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <!-- add here the contents -->
        <div class="px-4 py-16  sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <p class="py-5 text-3xl font-sans font-bold">Products</p>
          <p class="py-5 text-lg">Recomended for you</p>
          <div class="grid max-w-sm gap-5 mb- lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 sm:mx-auto lg:max-w-full">
          </div>
          <!-- new product section -->
          <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            
            <div class="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
              <a v-for="(product, index) in products" :key="index" href="/" aria-label="View Item">
                <div
                  class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                  <img class="object-cover w-full h-56 md:h-64 xl:h-80"
                    :src="getProductUrl(product)"
                    alt="" />
                  <div
                    class="absolute inset-x-0 bottom-0 px-6 py-16  h-full bg-gradient-to-r from-blue-900 bg-opacity-75">
                    <p class="text-sm font-medium  text-left lg:mt-24 py-6
                      tracking-wide text-white">
                      {{ product.name }}
                    </p>
                    <div class="grid grid-cols-3">
                      <div v-if="!product.isActive">
                        <router-link :to="{name: componenetMeta.productPage, params: { id: product.id } , query: {productType: product.productType} }" class="btn bg-green-500 border-white text-xs text-white ">grab now</router-link>
                      </div>
                        <div v-else>
                        <router-link :to="{name: componenetMeta.productPage, params: { id: product.id } , query: {productType: product.productType} }" class="btn bg-orange-500 border-white text-xs text-white ">Activated</router-link>
                      </div>
                      <div class="flex items-center">
                        <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                          </path>
                        </svg>
                        <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                          </path>
                        </svg>
                        <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                          </path>
                        </svg>
                        <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                          </path>
                        </svg>
                        <svg class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              
            </div>
            <div class="text-center">
              <a href="/" aria-label=""
                class="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                See more
                <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                  <path
                    d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                  </path>
                </svg>
              </a>
            </div>
          </div>
          <!-- end of new product section -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import ShopSidebar from '@/components/Shop/ShopSidebar.vue';
  import helperMixin from '@/mixins/helper';
  
  export default {
    props:['loggedIn','activeProducts'],
    mixins: [helperMixin],
    name: 'App',
    components: {
      ShopSidebar
    },
    data() {
      return {
        allProducts: [],
        filters: {
          productType:[],
          availableTo:[]
        }
      }
    },
    async mounted() {
      let { data } = await this.axios.get('shop/products', {
        loadingMesssage: "Getting Porducts"
      })
      this.allProducts = data.data
    },
    computed:{
      products:function() {
        let filteredData = this.allProducts.filter((product)=> {
         
          if(this.activeProducts.includes(product.id)) {
            product.isActive = true
          }else{
            product.isActive = false
          }
          
          let isNotFilteredByProductType = true

          let productType = product.productType
          if(this.filters.productType.length){
            if(this.filters.productType.includes(productType)) {
              isNotFilteredByProductType = true
            }else{
              isNotFilteredByProductType = false
            }
          }
          let isNotFilteredByAvailableTo = true

          let availableTo = product.available_to
          if(this.filters.availableTo.length) {
            if(this.filters.availableTo.includes(availableTo)) {
              isNotFilteredByAvailableTo = true
            }else{
              isNotFilteredByAvailableTo = false
            }
          }
          return isNotFilteredByProductType && isNotFilteredByAvailableTo
        })
        console.log(filteredData)
        return filteredData
      },
      componenetMeta:function() {
        return {
          productPage:this.loggedIn ? 'ProductDetail' : 'MazuProduct'
        }
      }
    },
    methods:{
      filterShop(data) {
        console.log(data)
        this.filters = data
      },
      purchaseProduct(product) {
        console.log(product)
      },
    }
  }
</script>