import { createApp } from 'vue'
import axios from './axios'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

const app = createApp(App)


app.use(router)
app.use(store)
app.use(Notifications)
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(VueAxios, axios)
app.use(VueSweetalert2);
app.use(VueProgressBar, {
  color: "#29B8A6",
  failedColor: "#874b4b",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
  position:"relative"
})
app.mount('#app')
