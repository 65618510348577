<template>
  <mazu-loader :text="$store.state.core.loadingText"></mazu-loader>

  <notifications style="margin-right:20px;margin-top:50px" />
   <the-header v-if="showNav"></the-header>
  <router-view></router-view>
   <the-footer v-if="showFooter"></the-footer>
</template>

<script>
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import MazuLoader from './components/MazuLoader.vue' 

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    MazuLoader
  },
  async created() {
    this.initProgressBar()
  },
  computed:{
    showNav() {
      if(this.$route.path.includes('/dashboard')) return false
      if(this.$route.path.includes('/admin')) return false
      return true
    },
    showFooter(){
      if(this.$route.path.includes('/admin')) return false
      return true
    }
  },methods:{
    initProgressBar() {
      this.$Progress.start();
      this.$router.beforeEach((to, from, next) => {
        this.$Progress.start();
        next();
      });
      this.$router.afterEach(() => {
       this.$Progress.finish();
      });
    }
  }
}
</script>
