<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <!-- <img class="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg" /> -->
          <span class="  text-xl">Register Mazu Account</span>
        </div>
        <!-- <div class="flex justify-between items-center h-14 bg-teal-800 dark:bg-gray-800 header-right">
          <div class="bg-white rounded flex items-center w-full max-w-xl mr-4 p-2 shadow-sm border border-gray-200">
            <button class="outline-none focus:outline-none">
              <svg class="w-5 text-gray-600 h-5 cursor-pointer" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
            <input type="search" name="" id="" placeholder="Search" class="w-full pl-3 text-sm text-black outline-none focus:outline-none bg-transparent" />
          </div>
          
          </div> -->
      </div>
      <!-- ./Sidebar -->
      
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 md:ml-64">
        <!-- component -->
        <div class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <!-- Author: FormBold Team -->
          <div class="mx-auto mt-12  lg:w-4/5  bg-white font-sans" >
            <div class="w-full mt-4 px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Account Name
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="Account Name"
                  v-model="form.account_name"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
              </div>
              <div class="input-errors" v-for="error of v$.form.account_name.$errors" :key="error.$uid">
                <span v-if="v$.form.account_name.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Account Description
                </label>
                <textarea
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="Account Description"
                  v-model="form.account_description"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  ></textarea>
              </div>
              <div class="input-errors" v-for="error of v$.form.account_description.$errors" :key="error.$uid">
                <span v-if="v$.form.account_description.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div>
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Contact Name</h1>
            </div>
            <div class=" px-5" >
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    First Name
                    </label>
                    <input
                      type="text"
                      name="fName"
                      id="fName"
                      placeholder="First Name"
                      v-model="form.first_name"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                  </div>
                  <div class="input-errors" v-for="error of v$.form.first_name.$errors" :key="error.$uid">
                    <span v-if="v$.form.first_name.$error" class="form-error">{{ error.$message }}</span>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Last Name
                    </label>
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      v-model="form.last_name"
                      placeholder="Last Name"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.last_name.$errors" :key="error.$uid">
                      <span v-if="v$.form.last_name.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Email
                    </label>
                    <input
                      type="text"
                      name="Email"
                      v-model="form.email"
                      id="Email"
                      placeholder="Email"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.email.$errors" :key="error.$uid">
                      <span v-if="v$.form.email.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Mobile Phone
                    </label>
                    <input
                      type="text"
                      name="lName"
                      v-model="form.mobilenumber"
                      id="lName"
                      placeholder="Mobile Phone"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.mobilenumber.$errors" :key="error.$uid">
                      <span v-if="v$.form.mobilenumber.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Phone
                    </label>
                    <input
                      type="text"
                      name="Phone"
                      id="Phone"
                      v-model="form.phonenumber"
                      placeholder="Phone"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.phonenumber.$errors" :key="error.$uid">
                      <span v-if="v$.form.phonenumber.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Phone (alternative)
                    </label>
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      v-model="form.phonenumber_alt"
                      placeholder="Phone (alternative)"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.phonenumber_alt.$errors" :key="error.$uid">
                      <span v-if="v$.form.phonenumber_alt.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Fax
                    </label>
                    <input
                      type="text"
                      name="Fax"
                      id="Fax"
                      v-model="form.fax"
                      placeholder="Fax"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.fax.$errors" :key="error.$uid">
                      <span v-if="v$.form.fax.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Country
                    </label>
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      v-model="form.country"
                      placeholder="Country"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.country.$errors" :key="error.$uid">
                      <span v-if="v$.form.country.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Language
                    </label>
                    <input
                      type="text"
                      name="Fax"
                      id=" Language"
                      v-model="form.language"
                      placeholder=" Language"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.language.$errors" :key="error.$uid">
                      <span v-if="v$.form.language.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                    >
                    Country
                    </label>
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      placeholder="Country"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                  </div> -->
              </div>
              <div>
                <h1 class="mb-5 font-bold font-sans text-teal-500">Billing Address</h1>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Post Code
                    </label>
                    <input
                      type="text"
                      name="Fax"
                      id="Fax"
                      v-model="form.post_code"
                      placeholder="Post Code"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.post_code.$errors" :key="error.$uid">
                      <span v-if="v$.form.post_code.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    City
                    </label>
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      v-model="form.city"
                      placeholder="City"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.city.$errors" :key="error.$uid">
                      <span v-if="v$.form.city.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Street and House Number
                    </label>
                    <input
                      type="text"
                      name="Fax"
                      id="Fax"
                      v-model="form.street_house_no"
                      placeholder="Street and House Number
                      "
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.street_house_no.$errors" :key="error.$uid">
                      <span v-if="v$.form.street_house_no.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Country
                    </label>
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      v-model="form.billing_country"
                      placeholder="Country"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.billing_country.$errors" :key="error.$uid">
                      <span v-if="v$.form.billing_country.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5 ">
                    <label
                      for="fName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Email Address for invoices
                    </label>
                    <input
                      type="text"
                      name="Fax"
                      id="Fax"
                      placeholder="Email Address for invoices"
                      v-model="form.email_invoice"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.email_invoice.$errors" :key="error.$uid">
                      <span v-if="v$.form.email_invoice.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <label
                      for="lName"
                      class="mb-3 block text-base font-medium text-gray-700 font-sans"
                      >
                    Email address for reminders
                    </label>
                    <input
                      type="text"
                      name="lName"
                      id="lName"
                      v-model="form.email_remainder"
                      placeholder="Email address for reminders"
                      class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    <div class="input-errors" v-for="error of v$.form.email_remainder.$errors" :key="error.$uid">
                      <span v-if="v$.form.email_remainder.$error" class="form-error">{{ error.$message }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-start mb-6">
                <div class="flex items-center h-5">
                  <input v-model="form.remainder_email_differs" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" required="">
                  <div class="input-errors" v-for="error of v$.form.remainder_email_differs.$errors" :key="error.$uid">
                    <span v-if="v$.form.remainder_email_differs.$error" class="form-error">{{ error.$message }}</span>
                  </div>
                </div>
                <div class="text-sm ml-3">
                  <label for="remember" class="font-medium text-gray-900">Reminder address differs from invoice address
                  Do not send reminders to the invoice address</label>
                </div>
              </div>
              <div class="mb-4">
                <button
                  class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                  @click="addMazuAcount"
                  >
                Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { useVuelidate } from '@vuelidate/core'
  import { required , minLength } from '@vuelidate/validators'
   export default {
     setup () {
       return { v$: useVuelidate() }
     },
     validations () {
     return {
       // usernameEmail: { required },
       // password: { required , minLength:minLength(8) },
  
       form:{
           account_name: { required },
           account_description: { required },
           first_name: { required },
           last_name: { required },
           email: { required },
           phonenumber: { required },
           mobilenumber:{ required },
           phonenumber_alt:{ required },
           fax: { required },
           country: { required },
           language: { required },
           post_code: { required },
           city: { required },
           billing_country: { required },
           street_house_no: { required },
           email_invoice: { required },
           email_remainder:{ required },
           remainder_email_differs:false
         }
  
  
     }
   },
     data() {
       return {
         form:{
           account_name: "",
           account_description: "",
           first_name: "",
           last_name: "",
           email: "",
           phonenumber: "",
           mobilenumber:"",
           phonenumber_alt:"",
           fax: "",
           country: "",
           language: "",
           post_code: "",
           billing_country: "",
           street_house_no: "",
           city:"",
           email_invoice: "",
           email_remainder:"",
           remainder_email_differs:false
         }
       }
     },
     methods:{
       async addMazuAcount() {
         console.log(this.form)
           const isFormCorrect = await this.v$.$validate()
           if(!isFormCorrect) {
              return this.$notify({ title:"Error",type: "error", text: "Please fillout all the required fields" }); 
           }
           try{
            let { data } = await this.axios.post('user/accounts',this.form,{
             loadingMesssage:"Loading .."
           })
           this.$notify({ title:"Message",type: "success", text: "Account created Successfully" });
           this.$store.commit("activeAccount",data.data)
           this.$store.commit("userAccounts",data.accounts)
           this.$router.push(`/dashboard/payment/${data.data.id}`)
         }catch(e) {
           console.log(e)
           let { data : { message } } = e.response
           this.error = message
           this.$notify({ type:"error",title: "Error", text: this.error });
         }
       }
     }
   }
</script>