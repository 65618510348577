<template>
  <ProductdetailComponent
  :loggedIn="true"
  :isProductActivated="isProductActivated"
  />
</template>

<script>
import ProductdetailComponent from '@/components/Shop/ProductdetailComponent.vue'
export default {
  components:{
    ProductdetailComponent
  },
  data(){
    return {
      isProductActivated:false
    }
  },
  async created() {
    try {
      let { data } = await this.axios.get(`user/accounts/product/${this.$store.state.core.activeAccount.id}?productType=${this.$route.query.productType}`)
      let productTypeKey
      if(this.$route.query.productType == 'extension') productTypeKey = 'Extensions'
      if(this.$route.query.productType == 'plugin') productTypeKey = 'Plugins'
      if(this.$route.query.productType == 'theme') productTypeKey = 'Themes'
      let accountActiveProducts = data.data[productTypeKey].map(p => p.id)
      if(accountActiveProducts.includes(this.$route.params.id)) {
        this.isProductActivated = true
      }
      console.log(accountActiveProducts)
    //  let allProducts = data.data
    //  this.extensions = allProducts.Extensions
    //  this.themes = allProducts.Themes
    //  this.plugins = allProducts.Plugins
   } catch(e) {
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: this.error });
      // this.$router.push('/dashboard/shop')
    }
  }
}
</script>