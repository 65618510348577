<style>
  /* Custom style */
  .header-right {
    width: calc(100% - 3.5rem);
  }
  
  .sidebar:hover {
    width: 16rem;
  }
  
  @media only screen and (min-width: 768px) {
    .header-right {
      width: calc(100% - 16rem);
    }
  }
</style> <template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <!-- <img class="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg" /> --> <span class="  text-xl">MAZU System</span>
        </div>
      </div> <!-- ./Header -->
      <!-- ./Sidebar -->
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="grid gap-5 ">
            <div class="flex flex-col justify-top"> <img src="https://st4.depositphotos.com/3519321/21463/i/600/depositphotos_214637424-stock-photo-tornado-warning-sign-powerful-stormy.jpg" style="height:600px"> <br> <a href="#" @click="restartMazu" class="relative flex bg-red-500 btn flex-row items-left h-11 focus:outline-none hover:bg-red-600 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700"> <span class="inline-flex justify-left items-left ml-4">
                  <Icon icon="ic:outline-warning" height="24" :inline="true" />
                </span> <span class="ml-2 text-xs tracking-wide truncate text-white">Restart mazu</span> </a> </div>
          </div>
        </div> <!-- start if login and register info -->
        <!-- end of login and regitser -->
      </div>
    </div>
  </div>
</template>
<script>
  import {
    Icon
  } from '@iconify/vue';
  export default {
    components: {
      Icon
    },
    methods: {
      async restartMazu() {
        let result = await this.$swal({
          title: "Restart Mazu",
          text: "This will restart the Flirttool Dating community. Are you sure that you have checked all Db connections and other required settings?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Restart mazu!"
        })
        if (result.isConfirmed) {
          try{
            await this.axios.post('system/restart', {
              loadingMesssage: "Loading.."
            })
          }catch(e) {
            if(e.response?.data?.status == 401) return this.$notify({ title: "Error", text: e.response.data.message });
            this.$store.commit('loading',true)
            setTimeout(async () => {
              try {
                await this.axios.post('system/ping', {
                  loadingMesssage: "Loading.."
                })
                this.$notify({ title:"Message",type: "success", text: "Mazu restarted successfully" });
              }catch (e) {
              let { data : { message } } = e.response
                this.error = message
                this.$notify({ title: "Error", text: this.error });
              }
            }, 1000)
          }
        }
      }
    }
  }
</script>