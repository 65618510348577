// Website
import { createWebHistory, createRouter } from 'vue-router'
import Login from '../views/MazuLogin.vue'
import Home from '../views/MazuHome.vue'
import Company from '../views/MazuCompany.vue'
import Register from '../views/MazuRegister.vue'
import Info from '../views/MazuInfo.vue'
import Payment from '../views/MazuPayment.vue'
import Shop from '../views/MazuShop.vue'
import MazuProduct from '../views/MazuProduct.vue'
import Pricing from '../views/Pricing.vue'
import MazuVersions from '../views/MazuVersions.vue'



// Dashboard
import UserBasicinfo from '../views/MazuDashboard/UserBasicinfo.vue'
import UserAccounts from '@/views/MazuDashboard/UserAccounts.vue'
import RegisterMazu from '@/views/MazuDashboard/RegisterMazu.vue'
import MazuPayment from '@/views/MazuDashboard/MazuPayment.vue'
import DashboardShop from '../views/MazuDashboard/MazuShop.vue'
import Account from '../views/MazuDashboard/MazuAccount.vue'
import Dashboard from '../views/MazuDashboard/DashboardIndex.vue'
import PaymentSuccess from '@/views/MazuDashboard/PaymentSuccess.vue'
import PaymentFailed from '@/views/MazuDashboard/PaymentFailed.vue'
import JoinDatingcommunity from '@/views/MazuDashboard/JoinDatingcommunity.vue'
import ViewDatingcommunity from '@/views/MazuDashboard/ViewDatingcommunity.vue'
import ProductDetail from '@/views/MazuDashboard/ProductDetail.vue'
import AccountProducts from '@/views/MazuDashboard/AccountProducts.vue'


// Guards
import { authGuard ,adminAuthGuard ,paidAccountGuard  ,activeAccountGuard} from '../utils/helpers'




//admin 
import MazuAdmin from '../views/MazuAdmin/AdminIndex.vue'
import AdminHome from '../views/MazuAdmin/AdminHome.vue'
import MazuSystem from '../views/MazuAdmin/MazuSystem.vue'
import AdminExtensions from '../views/MazuAdmin/Extensions/AdminExtensions.vue'
import AddExtension from '../views/MazuAdmin/Extensions/AddExtension.vue'
import UpdateExtension from '../views/MazuAdmin/Extensions/UpdateExtension.vue'
import ExtensionGallery from '../views/MazuAdmin/Extensions/ExtensionGallery.vue'
import ExtensionPrices from '../views/MazuAdmin/Extensions/ExtensionPrices.vue'
import AdminThemes from '../views/MazuAdmin/Themes/AdminThemes.vue'
import AddTheme from '../views/MazuAdmin/Themes/AddTheme.vue'
import UpdateTheme from '../views/MazuAdmin/Themes/UpdateTheme.vue'
import ThemeGallery from '../views/MazuAdmin/Themes/ThemeGallery.vue'
import ThemePrices from '../views/MazuAdmin/Themes/ThemePrices.vue'
import AdminPlugins from '../views/MazuAdmin/Plugins/AdminPlugins.vue'
import AddPlugin from '../views/MazuAdmin/Plugins/AddPlugin.vue'
import UpdatePlugin from '../views/MazuAdmin/Plugins/UpdatePlugin.vue'
import PluginGallery from '../views/MazuAdmin/Plugins/PluginGallery.vue'
import PluginPrices from '../views/MazuAdmin/Plugins/PluginPrices.vue'
import MazuAdmins from '@/views/MazuAdmin/Admins/MazuAdmins.vue'
import AddAdmin from '@/views/MazuAdmin/Admins/AddAdmin.vue'
import AdminMazus from '../views/MazuAdmin/Mazus/AdminMazus.vue'
import AddMazu from '../views/MazuAdmin/Mazus/AddMazu.vue'
import UpdateMazu from '../views/MazuAdmin/Mazus/UpdateMazu.vue'
import MazuGallery from '../views/MazuAdmin/Mazus/MazuGallery.vue'
import MazuPrices from '../views/MazuAdmin/Mazus/MazuPrices.vue'
import MazuContent from '../views/MazuAdmin/Mazus/MazuContent.vue'
import DatingCommunities from '../views/MazuAdmin/DatingCommunities/DatingCommunities.vue'
import DatingCommunitysetting from '../views/MazuAdmin/DatingCommunities/DatingCommunitysettings.vue'
import UpdateDburl from '../views/MazuAdmin/DatingCommunities/UpdateDburl.vue'
import AddMazuContent from '../views/MazuAdmin/Content/AddContent.vue'
import AdminMazuContent from '../views/MazuAdmin/Content/AdminMazuContent.vue'
import UpdateContent from '../views/MazuAdmin/Content/UpdateContent.vue'
import AllUsers from '../views/MazuAdmin/Users/AllUsers.vue'
import AdminLogin from '../views/MazuAdmin/AdminLogin.vue'


const routes = [
    { path: '/', component: Home ,name:'home'},
    { path: '/company', component: Company ,name:'company'},
    { path: '/login', component: Login },
    { path: '/mazu-login', component: AdminLogin },

    { path: '/register', component: Register },
    { path: '/mazu', component: Info },
    { path: '/payment', component: Payment },
    { path: '/pricing', component: Pricing , },
    { path: '/shop', component: Shop  },
    { path: '/mazu-versions', component: MazuVersions  },

    //
    { path: '/product/:id', component: MazuProduct ,name:"MazuProduct"  },


    //AccountProducts


    { 
      path: '/dashboard', 
      component: Dashboard,
      name:"dashboard", 
      beforeEnter: (to, from, next) => {
        authGuard(to, from, next)
      },
      children:[
        { path: '', component: Account,name:"account" },
        { path: 'basicinfo', component: UserBasicinfo , name:"userbasicinfo" },
        { path: 'shop', component: DashboardShop , name:"DashboardShop" },
        { path: 'product-detail/:id', component: ProductDetail , name:"ProductDetail" },
        { path: 'accounts', component: UserAccounts },
        { path: 'register', component: RegisterMazu },
        { path: 'payment/:id', component: MazuPayment , name:"MazuPayment" ,beforeEnter:(to,from,next) =>{
          activeAccountGuard(to,from,next)
        }},
        { path: 'products', component: AccountProducts , name:"AccountProducts" },

        { path: 'success', component: PaymentSuccess , name:"PaymentSuccess" },
        { path: 'error', component: PaymentFailed , name:"PaymentFailed" },
        { path: 'joindating', component: JoinDatingcommunity , name:"JoinDatingcommunity" , beforeEnter:(to,from,next) =>{
          paidAccountGuard(to,from,next)
        }},
        { path: 'viewdating', component: ViewDatingcommunity , name:"ViewDatingcommunity" , beforeEnter:(to,from,next) =>{
          paidAccountGuard(to,from,next)
        }},

      ]
    },
    { 
      path: '/admin', 
      component: MazuAdmin,
      name:"admin", 
      beforeEnter: (to, from, next) => {
        adminAuthGuard(to, from, next)
      },
      children:[
        { path: '', component: AdminHome,name:"adminHome" },
        { path: 'system', component: MazuSystem,name:"MazuSystem" },

        
        


        { path: 'extensions', component: AdminExtensions,name:"adminExtensions" },
        { path: 'extensions/add', component: AddExtension,name:"addExtensions" },
        { path: 'extensions/edit/:id', component: UpdateExtension,name:"updateExtensions" },
        { path: 'extensions/gallery/:id', component: ExtensionGallery,name:"extensionGallery" },
        { path: 'extensions/prices/:id', component: ExtensionPrices,name:"extensionPrices" },


        { path: 'themes', component: AdminThemes,name:"adminThemes" },
        { path: 'themes/add', component: AddTheme,name:"addThemes" },
        { path: 'themes/edit/:id', component: UpdateTheme,name:"updateThemes" },
        { path: 'themes/gallery/:id', component: ThemeGallery,name:"themeGallery" },
        { path: 'themes/prices/:id', component: ThemePrices,name:"themePrices" },


        { path: 'plugins', component: AdminPlugins,name:"adminPlugins" },
        { path: 'plugins/add', component: AddPlugin,name:"addPlugins" },
        { path: 'plugins/edit/:id', component: UpdatePlugin,name:"updatePlugins" },
        { path: 'plugins/gallery/:id', component: PluginGallery,name:"pluginGallery" },
        { path: 'plugins/prices/:id', component: PluginPrices,name:"pluginPrices" },


        { path: 'mazus', component: AdminMazus,name:"adminMazus" },
        { path: 'mazus/add', component: AddMazu,name:"addMazus" },
        { path: 'mazus/edit/:id', component: UpdateMazu,name:"updateMazus" },
        { path: 'mazus/gallery/:id', component: MazuGallery,name:"mazuGallery" },
        { path: 'mazus/content/:id', component: MazuContent,name:"mazuContent" },

        { path: 'mazus/prices/:id', component: MazuPrices,name:"mazuPrices" },


        { path: 'mazu-content/add', component: AddMazuContent,name:"AddMazuContent" },
        { path: 'mazu-content', component: AdminMazuContent,name:"AdminMazuContent" },
        { path: 'mazu-content/edit/:id', component: UpdateContent,name:"UpdateContent" },

        { path: 'dating-communities', component: DatingCommunities,name:"DatingCommunities" },
        { path: 'dating-community/:id', component: DatingCommunitysetting,name:"DatingCommunitysetting" },
        { path: 'dating-community/update-db/:id', component: UpdateDburl,name:"UpdateDburl" },

        



         { path: 'users', component: AllUsers,name:"allUsers" },
         { path: 'admins', component: MazuAdmins,name:"MazuAdmins" },
         { path: 'admins/add', component: AddAdmin,name:"AddAdmin" },

         
         

         





      ]
    },
   

]

const router = createRouter({
    history: createWebHistory(),
    routes,
  })

export default router