<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">Mazu Content</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                 Mazu Content
                </h2>
             </div>
               <div class="">
                <!-- component -->
                <div class="flex items-center lg:-mt-10  px-5 justify-center p-12" >
                  <!-- Author: FormBold Team -->
                  <div class="mx-auto  lg:w-4/5  bg-white font-sans" >
                  <div class=" px-5 mt-12 mb-12" >
                    <vue3-tags-input v-model:tags="tags"
                      v-model="tag"
                      :select="true"
                      :select-items="unselectedContent"
                      @on-select="handleSelectedTag"
                      @on-tags-changed="handleChangeTag"
                      @on-remove="handleRemoveTag"
                      placeholder="Select the content">
                        <template #item="{ tag }">
                          {{ tag.name }}
                        </template>
                        <template #no-data>
                          No Data
                        </template>
                        <template #select-item="tag">
                          {{ tag.name }}
                        </template>
                     </vue3-tags-input>
                     <br><br>
                     <div>
                        <button
                        @click="addContent"
                          class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                          >
                        Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import Vue3TagsInput from 'vue3-tags-input';
export default {
  components: {
    Vue3TagsInput
  },
  async mounted() {
     let { data } = await this.axios.get(`admin/mazu/${this.$route.params.id}`,{
      loadingMesssage:"Getting Mazu version"
    })
    if(!data.data){
      alert('no data found')
    }
    data = data.data
    this.tags = data.MazuContents.map((content)=>{
      return {
        id:content.id,
        name:content.name
      }
    })
     let mazuContent = await this.axios.get(`admin/mazu-content`,{
      loadingMesssage:"Getting Mazu content"
    })
    this.content = mazuContent.data.data
    this.setUnselectedContent()
  },
   data(){
    return {
        content:[],
         tags: [],
        unselectedContent:[] 
      }
  },
   methods: {
     async addContent() {
       try {
        let selectedContent = this.tags.map(t => t.id)
        let { data } = await this.axios.post(`admin/mazu/content`,{
          mazuId:this.$route.params.id,
          content:selectedContent
        },{
          loadingMesssage:"Adding mazu content"
        })
         this.$notify({ title:"Message",type: "success", text: "Content Updated Suuccessfully" });

       }catch(e){
         let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
       }
      
     },
    handleSelectedTag(tag) {
      this.tags.push(tag);
       this.setUnselectedContent()
    },
    handleRemoveTag(tag) {
      console.log(tag)
    },
    setUnselectedContent() {
       let content = this.content.filter((c) => {
        let id = c.id
        let findId = this.tags.find(t => t.id == id)
        if(!findId){
          return true
        } 
      })
      this.unselectedContent = content
    },
    handleChangeTag(tags) {
      this.tags = tags;
      this.setUnselectedContent()
    },
  }
}
</script>

<style>
input:disabled {
  background: #dddddd;
  border:#dddddd;
}
.opaque{
  opacity: 0.1 !important;
}
</style>

