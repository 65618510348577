<template>
  <div class="px-4 py-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
  <p class="py-5 text-3xl font-sans font-bold">Themes</p>
  <p class="py-5 text-lg">Select community theme</p>
  <div class="grid max-w-sm gap-5 mb- lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 sm:mx-auto lg:max-w-full"></div>
  <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
     <a  v-for="theme in themes" :key="theme" href="#" aria-label="View Item">
      <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
        <img class="object-cover w-full h-56 md:h-64 xl:h-80" :src="getUrl(theme.theme_image)" alt="">
        <div class="absolute inset-x-0 bottom-0 px-6 py-16 h-full bg-gradient-to-r from-blue-900 bg-opacity-75">
          <p class="text-sm font-medium text-left lg:mt-24 py-6 tracking-wide text-white">{{theme.name}}</p>
          <div class="grid grid-cols-3">
            <div>
              <a v-if="theme.slug != active" @click.prevent="activateTheme(theme)" class="btn bg-green-500 border-white text-xs text-white">Activate</a>
              <a v-else href="#" class="btn bg-orange-500 border-white text-xs text-white">Active</a>

            </div>
            <div class="flex items-center">
              <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg class="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </a>
    
    </div>
  </div>
</div>
</template>
<script>
import helperMixin from '@/mixins/helper'
export default {
  mixins:[helperMixin],
  data() {
    return {
      themes:[],
      active:null
    }
  },
  async created() {
    try {
      let activeAccountId = this.$store.state.core.activeAccount.id
      let { data } = await this.axios.get(`user/themes/${activeAccountId}`, {
        loadingMesssage: "Loading"
      })
      this.themes = data.themes.Themes
      this.active = data.active
      
    }catch(e) {
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: this.error });
    }
  },
  methods: {
    async activateTheme(theme) {
      try {
        let activeAccountId = this.$store.state.core.activeAccount.id
        let { data } = await this.axios.post(`user/activateTheme/${activeAccountId}`, {
          activeTheme:theme.slug
        },{
          loadingMesssage: "Loading"
        })
        this.active = theme.slug
      } catch(e) {
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    }
  }
}
</script>