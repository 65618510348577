<template>
  <div class="h-full bg-teal-50">
        <div class="flex items-center  lg:-mt-10  px-5 " >
          <div class=" mt-12 pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Stripe Setup</h1>
            </div>
            <div class="px-3">
            <input
            type="text"
          v-model="stripeSecret"
            placeholder ="Stripe Secret"
            class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
            <button
            @click="setupStripeKey()"
              class=" mt-4 hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              >
            Update Key
            </button>
            </div>
          </div>
        </div>
      </div>

      <div class="h-full bg-teal-50 mt-12">
        <div class="flex items-center  lg:-mt-10  px-5 " >
          <div class=" mt-12 pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Paypal Setup</h1>
            </div>
            <div class="px-3">
            <input
            type="text"
            v-model="paypalClientId"
            placeholder ="Paypal Client ID"
            class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
             <input
            type="text"
            v-model="paypalClientSecret"
            placeholder ="Paypal Client Secret"
            class=" mt-8 w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
            <button
            @click="setupPaypal()"
              class=" mt-4 hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              >
            Update Key
            </button>
            </div>
          </div>
        </div>
      </div>

    


    
</template>
<script>
export default {
  data() {
    return {
      stripeSecret:'',
      paypalClientId:'',
      paypalClientSecret:''
    }
  },
  async created() {
    try {
      let activeAccountId = this.$store.state.core.activeAccount.id
      let { data } = await this.axios.get(`user/dating-community-meta/${activeAccountId}`, {
        loadingMesssage: "Loading"
      })
      this.stripeSecret = data.data.stripeSecret
      this.paypalClientId = data.data.paypalClientId
      this.paypalClientSecret = data.data.paypalClientSecret
    }catch(e) {
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: this.error });
    }
  },
  methods:{
    async setupStripeKey() {
      try {
        let activeAccountId = this.$store.state.core.activeAccount.id
        let { data } = await this.axios.put(`user/dating-community-meta/stripe/${activeAccountId}`, {
          stripeSecret:this.stripeSecret
        },{
          loadingMesssage: "Loading"
        })
       this.$notify({ title:"Message",type: "success", text: "Stripe secret updated" });
      }catch(e) {
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    },
    async setupPaypal() {
      try {
        let activeAccountId = this.$store.state.core.activeAccount.id
        let { data } = await this.axios.put(`user/dating-community-meta/paypal/${activeAccountId}`, {
          paypalClientId:this.paypalClientId,
          paypalClientSecret:this.paypalClientSecret,
        },{
          loadingMesssage: "Loading"
        })
       this.$notify({ title:"Message",type: "success", text: "Paypaly keys updated" });
      }catch(e) {
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    }
  }
}
</script>