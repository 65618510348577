<template>
  <div class="fixed flex flex-col top-14 left-0 w-14 hover:w-64 md:w-64 bg-white dark:bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar">
    <div class="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
      <ul class="flex flex-col py-4 space-y-1">
        <li class="">
          <a class="relative flex bg-white flex-row items-left h-11 focus:outline-none text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white pr-6">
            <span class="inline-flex justify-left items-left ml-4"> </span><span class="ml-2 text-lg font-sans font-semi-bold tracking-wide truncate text-teal-500">Product Type </span>
          </a>
        </li>
        <!-- This is an example component -->
        <div class="max-w-lg px-7">
          <fieldset class="mb-5 px-7">
            <div class="flex items-center items-start mb-4">
              <input @click="filter('extension','productType')" id="checkbox-1" aria-describedby="checkbox-1" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
              <label for="checkbox-1" class="text-sm ml-3 font-medium text-gray-900"> Extensions </label>
            </div>

            <div class="flex items-start items-center mb-4">
              <input @click="filter('theme','productType')" id="checkbox-2" aria-describedby="checkbox-2" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
              <label for="checkbox-2" class="text-sm ml-3 font-medium text-gray-900"> Themes</label>
            </div>

            <div class="flex items-start items-center mb-4">
              <input @click="filter('plugin','productType')" id="checkbox-3" aria-describedby="checkbox-3" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
              <label for="checkbox-3" class="text-sm ml-3 font-medium text-gray-900"> Plugins</label>
            </div>
        </fieldset>
        </div>

         <li class="">
          <a class="relative flex bg-white flex-row items-left h-11 focus:outline-none text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white pr-6">
            <span class="inline-flex justify-left items-left ml-4"> </span><span class="ml-2 text-lg font-sans font-semi-bold tracking-wide truncate text-teal-500">Available To </span>
          </a>
        </li>
         <!-- This is an example component -->
        <div class="max-w-lg px-7">
          <fieldset class="mb-5 px-7">
            <div class="flex items-center items-start mb-4">
              <input @click="filter('rent','availableTo')" id="checkbox-1" aria-describedby="checkbox-1" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
              <label for="checkbox-1" class="text-sm ml-3 font-medium text-gray-900"> Rent </label>
            </div>

            <div class="flex items-start items-center mb-4">
              <input  @click="filter('buy','availableTo')" id="checkbox-2" aria-describedby="checkbox-2" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
              <label for="checkbox-2" class="text-sm ml-3 font-medium text-gray-900"> Buy</label>
            </div>

            <div class="flex items-start items-center mb-4">
              <input @click="filter('both','availableTo')" id="checkbox-3" aria-describedby="checkbox-3" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
              <label for="checkbox-3" class="text-sm ml-3 font-medium text-gray-900"> Both</label>
            </div>
             <div class="flex items-start items-center mb-4">
              <input @click="filter('free','availableTo')" id="checkbox-3" aria-describedby="checkbox-3" type="checkbox" class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded" />
              <label for="checkbox-3" class="text-sm ml-3 font-medium text-gray-900"> Free</label>
            </div>
        </fieldset>
        </div>
      </ul>
      <!-- <p class="mb-14 px-5 py-3 hidden md:block text-center text-xs">Copyright @2021</p> -->
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        filters: {
          productType:[],
          availableTo:[]
        }
      }
    },
    methods:{
      filter(value,filterType) {
        if(this.filters[filterType].includes(value)){
          const index = this.filters[filterType].indexOf(value);
          this.filters[filterType].splice(index, 1);
        }else{
          this.filters[filterType].push(value)
        }
        this.$emit('filtered', this.filters)
      }
    }
  };
</script>
