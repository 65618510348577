<template>
  <ProductdetailComponent
  :loggedIn="false"
  />
</template>

<script>
import ProductdetailComponent from '@/components/Shop/ProductdetailComponent.vue'
export default {
  components:{
    ProductdetailComponent
  }
}
</script>