<template>
  <div class="relative h-screen">
    <img src="@/assets/images/login/BGImagesLoginPage.png" class="absolute inset-0 object-cover w-full h-screen" style="height:900px"
      alt="" />
    <div class="relative bg-gray-900 bg-opacity-0 h-screen">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="flex flex-col items-center justify-between xl:flex-row">
          <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
            <div class="bg-white  rounded shadow-2xl p-7 sm:p-10 ">
               <h1 class="mb-4 text-center  font-bold font-sans text-4xl text-gray-500 sm:text-center sm:mb-6 sm:text-4xl">
                Mazu Admin
              </h1>
                <div class="mb-1 sm:mb-2">
                  <label for="Email or Username" class="inline-block mb-1 font-medium">Email or
                  Username</label>
                  <input placeholder="John" type="text"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="Email or Username" name="Email or Username" v-model="usernameEmail" />
                    <div class="input-errors" v-for="error of v$.usernameEmail.$errors" :key="error.$uid">
                          <span v-if="v$.usernameEmail.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                </div>
                <!-- <div class="mb-1 sm:mb-2">
                  <label for="lastName" class="inline-block mb-1 font-medium">Last name</label>
                  <input
                    placeholder="Doe"
                    required=""
                    type="text"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="lastName"
                    name="lastName"
                  />
                  </div> -->
                <div class="mb-1 sm:mb-2">
                  <label for="email" class="inline-block mb-1 font-medium">Password</label>
                  <input placeholder="***********" type="text"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                    id="email" name="email" v-model="password" /> 
                      <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                          <span v-if="v$.password.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                </div>
                <p style="color:red">{{ error }}</p>
                <div class="mt-4 mb-2 sm:mb-4 bg-teal-500 hover:bg-teal-200 rounded-lg ">
                  <button @click="login"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                  Login
                  </button>
                </div>
            </div>
          </div>
          <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { required , minLength } from '@vuelidate/validators'
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return {
        usernameEmail:"",
        password:"",
        error:"",
        terms:false
    }
  },
  validations () {
    return {
      usernameEmail: { required },
      password: { required , minLength:minLength(8) },
    }
  },
  methods:{
    async login() {
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect) return 
      try{
        let { data } = await this.axios.post('admin/login',{
          userNameEmail:this.usernameEmail,
          password:this.password
        },{
          loadingMesssage:"Admin login .."
        })
        this.$notify({ title:"Message",type: "success", text: "Loggedin Successfully" });
        // console.log(data)
        this.$store.commit("userData",data.user)
        this.$store.commit("token",data.token)
        this.$store.commit("loggedIn",false)
        this.$store.commit("adminloggedIn",true)
        this.$router.push('/admin')
      }catch(e) {
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    }
  }
}
</script>