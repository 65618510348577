<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
       
      </div>
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 md:ml-64">
        <!-- component -->
        <div v-if="$store.state.core.activeAccount.is_paid == true" class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <div class="text-center shadow-xl mt-12 card">
            <figure class="h-full px-10 pt-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-2/3 text-green-600 h-52"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
              </svg>
            </figure>
            <div class="card-body">
              <h2 class="card-title">You're payment is successful</h2>
              <p>Account activated successfully</p>
            </div>
          </div>
        </div>
        <div v-else class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <div class="text-center shadow-xl mt-12 card">
            <div class="card-body">
              <h2 class="card-title">You're payment was not successful</h2>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import HelperMixin from '@/mixins/helper' 
  export default {
    mixins:[HelperMixin],
    async created() {
      this.refreshUserData()
    }
  }
</script>