<template>
  <div class="mt-14">
    <MazuPricing/>
  </div>
</template>

<script>
import MazuPricing from '@/components/Dashboard/MazuPricing.vue'
export default {
  components:{
    MazuPricing
  },
}
</script>