<template>
    <section class="
      px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8
      ">
      <div class="container">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">
              <!-- <span class="font-semibold text-lg text-primary mb-2 block">
                Pricing Table
                </span> -->
              <h2 class="
                font-bold
                text-5xl
                sm:text-4xl
                md:text-[40px]
                text-dark
                mb-4 text-teal-500
                ">
                PACKAGES
              </h2>
              <!-- <p class="text-base text-body-color">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
                </p> -->
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-center -mx-4" style="color:black">
          <div v-for="(mazu,index) in mazuVersions" :key="index" class="w-full md:w-1/2 lg:w-1/3 px-4">
            <div class="
              bg-white
              rounded-xl
              relative
              z-10
              overflow-hidden
              border border-teal-500 border-opacity-20
              shadow-pricing
              py-10
              px-8
              sm:p-12
              lg:py-10 lg:px-6
              xl:p-12
              mb-10
              ">
              <span class="text-teal-600 font-semibold text-center text-4xl block mb-4">
              {{ mazu.name }}
              </span>
              <h2 class="font-bold text-center text-dark mb-5 text-[42px] text-teal-500">
                € {{ formatPrice(mazu.Prices.find(p => p.currency == 'usd').price)  }}
                <span v-if="mazu.available_to == 'rent'" class="text-base text-body-color font-medium">
                / month
                </span>
                 <span v-else class="text-base text-body-color font-medium">
                   purchase
                </span>
              </h2>
              <p class="
                text-base text-body-color
                pb-8
                mb-8 
                text-teal-900 text-center font-bold uppercase
                ">
                MAZU Community <br>
                {{mazu.name}} Edition
                <br/>
              <p style="font-size:10px;">{{mazu.description}}</p>

              </p>
              <div class="mb-7">
                <p v-for="(content,index) in mazu.MazuContents" :key="index" class="text-base text-body-color leading-loose mb-1">
                  {{index +1 }}. {{ content.name }}
                </p>
              </div>
              <a href="javascript:void(0)"
              v-if="$route.params.id"
              @click="getPaymentLink(mazu)"
               class="
                w-full
                block
                text-base
                font-semibold
                bg-transparent
                border border-teal-500
                rounded-md
                text-center
                p-4
                hover:text-white hover:bg-primary hover:border-primary
                transition text-teal-500
                ">
              Select Now
              </a>
              <div>
                <span class="absolute right-0 top-7 z-[-1]">
                  <svg width="77" height="172" viewBox="0 0 77 172" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient id="paint0_linear" x1="86" y1="0" x2="86" y2="172"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3056D3" stop-opacity="0.09" />
                        <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
</template>

<script>
export default {
  data(){
    return {
      mazuVersions: []
    }
  },
  async created() {
    let { data } = await this.axios.get('data/mazu',{
        loadingMesssage:"Getting Mazu versions"
      })
      this.mazuVersions = data.data
      console.log(this.mazuVersions)
    },
    methods:{
      formatPrice(price) {
        if(price == 200000 ) {
          return '200,000'
        }
        return price
      },
      async getPaymentLink(mazu) {
        try{
          let { data } = await this.axios.post('user/accounts/payment',{
            accountId:this.$route.params.id,
            mazuId:mazu.id
          },{
             loadingMesssage:"Getting payment Link .."
          })
          let url = data.url
          window.location.href = url
          console.log(url)
        }catch(e) {
          let { data : { message } } = e.response
          this.error = message
          this.$notify({ title: "Error", text: this.error });
        }
      }
    }
  } 
</script>