<template>
  <div>
    <div
    
      class="fixed  flex flex-col top-14 left-0 w-14 hover:w-64 md:w-64 bg-teal-800 dark:bg-gray-900 h-full text-white transition-all duration-300 border-none z-10 sidebar" :class="{ 'bg-red-800':$store.state.core.userData.role == 'MAZU_DEVELOPER' }" >
      <div class="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
        <ul class="flex flex-col py-4 space-y-1 ml-2 mr-2">
            <SidebarButton link="/" text="Home" adminType="MAZU_ADMIN,MAZU_DEVELOPER"/>
            <SidebarButton link="/admin" text="Dashboard" adminType="MAZU_ADMIN"/>
            <SidebarButton link="/admin/users" text="Users" adminType="MAZU_ADMIN"/>
            <SidebarButton link="/admin/admins" text="Admins" adminType="MAZU_ADMIN"/>
            <SidebarButton link="/admin/mazus" text="Mazu Versions" adminType="MAZU_ADMIN"/>

            <SidebarButton link="/admin/mazu-content" text="Mazu Content" adminType="MAZU_ADMIN"/>
            <SidebarButton link="/admin/extensions" text="Extensions" adminType="MAZU_ADMIN"/>
            <SidebarButton link="/admin/themes" text="Themes" adminType="MAZU_ADMIN"/>
            <SidebarButton link="/admin/plugins" text="Plugins" adminType="MAZU_ADMIN"/>

            <SidebarButton link="/admin/dating-communities" text="Dating Communities" adminType="MAZU_ADMIN,MAZU_DEVELOPER"/>
            <SidebarButton link="/admin/system" text="Mazu System" adminType="MAZU_ADMIN,MAZU_DEVELOPER"/>

            
          <li class="">
            <a href="#" @click="logOut"
              class="relative flex bg-teal-500 btn flex-row items-center h-11 focus:outline-none hover:bg-teal-300 dark:hover:bg-gray-600 text-white- hover:text-white-800 border-l-4 border-transparent hover:border-white dark:hover:border-gray-800 pr-6 hover:text-gray-700"
               :class="{ 'bg-red-800 hover:bg-black':$store.state.core.userData.role == 'MAZU_DEVELOPER' }"
              >
              <span class="inline-flex justify-center items-center ml-4">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                  </path>
                </svg>
              </span><span class="ml-2 text-xs tracking-wide truncate text-white">Logout</span>
            </a>
          </li>
         </ul>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarButton from './SidebarButton.vue'
 export default {
   components:{
     SidebarButton
   },
    methods:{
      logOut(){
       this.$store.commit("userData",{})
       this.$store.commit("adminloggedIn",false)
       this.$store.commit("token",null)
       this.$router.push('/mazu-login')
      }
    }
  }
</script>