<template>
  <input 
  ref="inputRef"
  class="mb-2 mt-2"
    type="file" 
    name="file"
    @change="changed"
    :multiple = multiple
    accept=".jpg, .jpeg, .png"
  />
  <button 
    @click="openInput"
    class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none mb-2 mt-2">{{ buttonText }}
  </button>
  <div class="selected-files mb-5" v-if="this.files.length">
    <div class="container flex flex-wrap mx-auto" ref="selectedFiles">
      <div v-for="(file , index) in files" :key="index" class="w-full p-2 rounded  relative group" :class="classObject">
          <img :src="file.fileData.result" alt="image"/>
            <div class="opacity-0 group-hover:opacity-40 bg-teal-800  duration-300 absolute inset-0 flex justify-center items-center z-1000">
                <a @click.prevent="deleteImage(index)" href="#"><Icon icon="mdi-light:delete" style="color:red" height="24"/></a>
            </div>
      </div>
    </div>
  <p class="text-center">{{ imagesText }}</p>

  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import { toRaw } from 'vue';
export default {
  components:{
    Icon
  },
  props:{
    multiple:{
      type:Boolean,
      default:false
    },
    buttonText:{
      type:String,
      default:"Upload"
    },
    imagesText:{
      type:String,
      default:""
    },
    fileDataKey:{
      type:String,
      default:'data'
    }
  },
  data() {
    return {
      files :[],
      showSelectedFiles:false
    } 
  },
  methods:{
    async changed() {
      await this.setFiles()
      let toSendData = this.getSendData()
      this.$emit('dataOut',{
        [this.fileDataKey] : toSendData
      })
      
    },
    reset(){
      this.files= []
    },
    deleteImage(i){
      this.files = this.files.filter((e,index) => index != i)
       let toSendData = this.getSendData()
        this.$emit('dataOut',{
          [this.fileDataKey] : toSendData
        })
    },
    async readFile(file) {
      return new Promise((resolve)=>{
        const reader = new FileReader();
        reader.onload = (evt) => {
          resolve(evt.target)
        };
        reader.readAsDataURL(file);
      })
    },
     setFiles() {
      return new Promise(async (resolve)=>{
      let files = []
        const fileList = this.$refs.inputRef.files;
        for (var i=0;i<fileList.length;i++){
          let fileData = await this.readFile(fileList[i])
          files.push({
            file:fileList[i],
            fileData:fileData
          })
        }
        this.files = files
        resolve()
      })
    },
    openInput(){
      this.$refs.inputRef.click()
    },
    getSendData(){
      let files = toRaw(this.files)
      return files.map(f => f.file)
    }
  },
  computed:{
    classObject() {
      return {
        'lg:w-1/3 md:w-1/2': this.files.length > 0
        }
    }
  }
}
</script>
<style scoped>
input{
  display: block;
  display: none;
}
button{
  display: block;
}
.selected-files{
  border: dashed 4px #29B8A6;
}

</style>