<template>
  <div class="relative h-screen mb-36">
    <img src="@/assets/images/login/BGImagesLoginPage.png" class="absolute inset-0 object-cover w-full h-screen"
      style="height:900px" alt="" />
    <div class="relative bg-gray-900 bg-opacity-0 h-screen">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="flex flex-col items-center justify-between xl:flex-row">
          <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
            <div class="bg-white  rounded shadow-2xl p-7 sm:p-10 ">
              <h1
                class="mb-4 text-center  font-bold font-sans lg:text-4xl xl:text-4xl 2xl:text-4xl text-5xl text-gray-500 sm:text-center sm:mb-6 sm:text-4xl">
                <span class="text-teal-500"> JOIN NOW</span> <span class="italic text-2xl"> for free</span>
              </h1>
              <router-link to="register">
                <p class="text-teal-500 underline text-center font-sans uppercase -mt-3">FlirtTool Dating Community
                </p>
              </router-link>
              <div class="py-5">
                <div class="grid grid-cols-2">
                  <div class="mt-4 mb-2 sm:mb-4 bg-white hover:bg-gray-300  bordered border-2 ">
                    <button type="submit"
                      class="inline-flex items-center justify-start text-xs w-full h-12 px-1 font-medium tracking-wide text-gray-500 transition duration-200 rounded hover:text-white shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                    <img src="@/assets/images/socialicons/google.png" alt="" class="h-5 px-5 uppercase "> <span class="uppercase text-2xs text-left">Login
                    with google</span> 
                    </button>
                  </div>
                  <div class="mt-4 mb-2 sm:mb-4 bg-white hover:bg-blue-500 bordered border-2 ">
                    <button type="submit"
                      class="inline-flex items-center text-2xs justify-center w-full h-12 px-1 font-medium tracking-wide text-gray-500 hover:text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                    <img src="@/assets/images/socialicons/facebook.png" alt="" class="h-5 px-5 ">
                   <span class="uppercase text-left">Login with Facebook</span> 
                    </button>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-2">
                  <div class="mb-1 sm:mb-2">
                    <label for="First Name" class="inline-block mb-1 text-sm">First Name</label>
                    <input v-model="first_name" placeholder="John" type="text"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="First Name" name="First Name" />
                      <!-- <span v-if="v$.first_name.$error" class="form-error">{{ v$.first_name.$error }}</span> -->
                       <div class="input-errors" v-for="error of v$.first_name.$errors" :key="error.$uid">
                          <span v-if="v$.first_name.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                    </div>

                  <!-- <p v-if="v$.first_name.$error">Name field has an error.</p> -->
                  <div class="mb-1 sm:mb-2">
                    <label for="email" class="inline-block mb-1 text-sm">Last Name</label>
                    <input v-model="last_name" placeholder="Last Name" type="text"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                       />
                      <div class="input-errors" v-for="error of v$.last_name.$errors" :key="error.$uid">
                          <span v-if="v$.last_name.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-2">
                  <div class="mb-1 sm:mb-2">
                    <label for="Username" class="inline-block mb-1 text-sm">Username</label>
                    <input  v-model="username" placeholder="John" type="text"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="Username" name="Username" />
                      <div class="input-errors" v-for="error of v$.username.$errors" :key="error.$uid">
                          <span v-if="v$.username.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label for="email" class="inline-block mb-1 text-sm">E-mail</label>
                    <input v-model="email" placeholder="example@example.com" type="text"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="email" name="email" />
                      <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                          <span v-if="v$.email.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-2">
                  <div class="mb-1 sm:mb-2">
                    <label for="8 Digit with Characters" class="inline-block mb-1 text-sm">Create a Password</label>
                    <input v-model="password" placeholder="8 Digit with Characters" type="text"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="Username" name="Username" />
                      <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                          <span v-if="v$.password.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                  </div>
                  <div class="mb-1 sm:mb-2">
                    <label  for="Confirm" class="inline-block mb-1 text-sm">Confirm</label>
                    <input v-model="confirm_password" placeholder="Re-enter your password" type="text"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-teal-500 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="email" name="email" />
                      <div class="input-errors" v-for="error of v$.confirm_password.$errors" :key="error.$uid">
                          <span v-if="v$.confirm_password.$error" class="form-error">{{ error.$message }}</span>
                      </div>
                  </div>
                </div>
                <div class="flex items-start mb-6">
                  <div class="flex items-center h-5">
                    <input v-model="terms" id="remember" aria-describedby="remember" type="checkbox"
                      class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                      required="">
                  </div>
                  <div class="text-xs font-sans ml-3">
                    <label for="remember" class=" font-sans text-gray-900">I AGREE TO THE TERMS,
                    <strong class="text-teal-500"> CONDITIONS AND PRIVACY
                    POLICY CLICK HERE TO VIEW OUR TERMS </strong></label>
                  </div>
                </div>
                <div class="mt-4 mb-2 sm:mb-4 bg-teal-500 hover:bg-teal-200 rounded-lg ">
                  <button @click="register"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                  REGISTER
                  </button>
                </div>
                <router-link to="/login">
                  <p class="text-center font-bold text-teal-500  text-sm"> Already Have An Account? Login</p>
                  <br> 
                  <p class="text-center text-sm">MAZU™ POWERED BY DIALOGMAKERS INTERNATIONAL LTD. 2021</p>
                </router-link>
                <p class="text-sm text-teal-500 text-center font-sans font-bold"><a href="terms">TERMS</a>  | <a href="privacy"> PRIVACY </a> | <a href="imprint"> IMPRINT </a></p>
              </div>
            </div>
          </div>
          <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email ,minLength } from '@vuelidate/validators'
import HelperMixin from '@/mixins/helper'
export default {
  mixins:[HelperMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      terms: false,
    }
  },
   validations () {
    return {
      first_name: { required },
      last_name: { required },
      username: { required },
      email: { required ,email },
      password: { required ,  minLength: minLength(5) },
      confirm_password: { required ,  minLength: minLength(5) },
    }
  },
  methods:{
   async register() {
     if(!this.terms){
        return  this.$notify({ title:"Message",type: "warn", text: "Please agree to Terms & Conditions" }); 
     }
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect) return
      if(this.password != this.confirm_password) {
        return this.$notify({ title:"Error",type: "error", text: "Passwords dosn't match" }); 
      } 
      // console.log( this.v$.email.$errors)
      // console.log(isFormCorrect)
      try{
        let { data } = await this.axios.post('user/register',{
          first_name:this.first_name,
          last_name:this.last_name,
          username:this.username,
          email:this.email,
          password:this.password,
        },{
          loadingMesssage:"Mazu Registration .."
        })
        this.$notify({ title:"Message",type: "success", text: "Loggedin Successfully" });
        this.logOutUser()
        this.$store.commit("userData",data.user)
        this.$store.commit("token",data.token)
        this.$store.commit("loggedIn",true)
        this.$router.push('/dashboard')
      }catch(e) {
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ type:"error",title: "Error", text: this.error });
      }
    }
  }
}
</script>

<style>
.form-error{
  color:red;
  font-size:12px
}
</style>