<template>
   <header v-if="$store.state.core.userAccounts.length > 0 && !disableHeader " style="z-index:9999" class="header fixed top-0 inset-x-0 p-4 bg-gray-900 shadow-md" :class="{ 'is-hidden': !showHeader }">
        <div style="float:right;position:relative;z-index:99999;background:black">
         <span class="text-white"> Mazu Account : </span>
         <select @change="_selectAccount" v-model="$store.state.core.activeAccount.id">
           <option style="font-weight:bold" :value="$store.state.core.activeAccount.id">{{ $store.state.core.activeAccount.account_name }}</option>
            <option v-for="(account,index) in $store.state.core.userAccounts" :key="index" 
             :value="account.id"
            >{{ account.name }}</option>
          </select>
        </div>
       <marquee direction="right" v-if="$store.state.core.activeAccount.is_paid == false" style="color:red;position:absolute;z-index:0">Your MAZU account <b style="color:white">{{ $store.state.core.activeAccount.account_name }}</b> is not activated, <router-link style="color:white" :to="{name: 'MazuPayment', params: { id: $store.state.core.activeAccount.id }}" >click here</router-link> to activate your mazu account. </marquee>
      </header>
</template>

<script>
import helperMixin from '@/mixins/helper';

export default {
    mixins: [helperMixin],
  data: () => ({
    showHeader: true,
    lastScrollPosition: 0,
    scrollOffset: 40,
  }),
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
  },
  computed:{
    disableHeader() {
      if(
        this.$route.name == 'MazuPayment' ||
        this.$route.name == 'JoinDatingcommunity' ||
        this.$route.name == 'ViewDatingcommunity'  ||
        this.$route.name == 'shop' ||
        this.$route.name == 'ProductDetail'||
       this.$route.name == 'DashboardShop'   ||
        this.$route.name == 'AccountProducts'
       //AccountProducts

        ) return true
      return false
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    // Toggle if navigation is shown or hidden
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
        return
      }
      this.showHeader = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    },
    _selectAccount(x){
      this.selectAccount(x.target.value)
    }
  }
}
</script>