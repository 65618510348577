<template>
  <!-- main section starts here -->

  <div class="relative">
    <img src="@/assets/11111.jpg" class="absolute inset-0 object-cover w-full h-full" alt="" />

    <div class="relative bg-gray-900 bg-opacity-0">
      <!-- second header -->
      <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between">
          <div class="flex items-center">
            <!-- <router-link to="/company" aria-label="Company" title="Company" class="inline-flex items-center mr-8">
        <img src="@/assets/images/mazulogo.png" alt="" class="w-44">
        <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">Company</span>
      </router-link> -->
            <ul class="flex items-center hidden space-x-8 lg:flex">
              <li><a href="/shop" aria-label="Our product" title="Our product"
                  class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Extensions</a>
              </li>
              <li><a href="/shop" aria-label="Our product" title="Our product"
                  class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Themes</a>
              </li>
              <li><a href="/shop" aria-label="Product pricing" title="Product pricing"
                  class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Shop</a>
              </li>
              <li><a href="/" aria-label="About us" title="About us"
                  class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Plans</a>
              </li>
            </ul>
          </div>
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <li> <router-link to="/register"><label for="my-modal-3"
                  class="btn bg-teal-500 text-white hover:bg-teal-400 border-0">Register</label></router-link>

              <!-- Put this part before </body> tag -->
              <input type="checkbox" id="my-modal-3" class="modal-toggle" />
              <div class="modal">
                <div class="modal-box relative">
                  <label for="my-modal-3" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                  <div class="px-5 pt-6 pb-5 text-center border border-gray-300 rounded lg:w-full">
                    <div class="mb-5 font-semibold text-2xl">Create an account</div>
                    <div class="flex justify-center grid grid-cols-2 w-full mb-3 ">
                      <div><a href="/"
                          class="inline-flex items-center bg-teal-500 justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                          <div class="flex items-center">
                            <div class="mr-3 font-semibold text-white ">Rent</div>

                          </div>
                        </a></div>
                      <div><a href="/"
                          class="inline-flex items-center bg-teal-500 justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                          <div class="flex items-center">
                            <div class="mr-3 font-semibold text-white ">Buy</div>

                          </div>
                        </a></div>
                    </div>
                    <!-- <p class="max-w-md px-5 mb-3 text-xs text-gray-600 sm:text-sm md:mb-5">
        Sed ut unde omnis iste natus accusantium doloremque laudantium omnis iste.
      </p> -->
                    <!-- <div class="flex items-center w-full mb-5">
        <hr class="flex-1 border-gray-300" />
        <div class="px-3 text-xs text-gray-500 sm:text-sm">or</div>
        <hr class="flex-1 border-gray-300" />
      </div> -->
                    <!-- <a href="/" class="inline-flex items-center justify-center w-full h-12 px-6 font-semibold transition duration-200 bg-white border border-gray-300 rounded md:w-auto hover:bg-gray-100 focus:shadow-outline focus:outline-none">
        Sign Up with Email
      </a> -->
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a href="/"
                class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide hover:bg-teal-400 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline bg-teal-500 border-0 font-bold text-uppercase focus:outline-none"
                aria-label="Sign up" title="Sign up">
                Contact Us
              </a>
            </li>
          </ul>
          <!-- Mobile menu -->
          <div class="">
 <router-link to="/login">
            <button class="btn bg-white text-black border-0 justify-center"> Login</button></router-link>
            <!-- <button aria-label="Open Menu" title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50">
              <div class="dropdown dropdown-bottom dropdown-end">
                <label tabindex="0" class="btn m-1 text-gray-800"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </label>
                <ul tabindex="0" class="dropdown-content text-gray-400 menu p-2 shadow bg-base-100 rounded-box w-52">
                  <li><a>Extensions</a></li>
                  <li><a>Themes</a></li>
                  <li><a>Shop</a></li>
                  <li><a>plan</a></li>
                </ul>
              </div>
            </button> -->
            <!-- Mobile menu dropdown 
      <div class="absolute top-0 left-0 w-full">
        <div class="p-5 bg-white border rounded shadow-none">
          <div class="flex items-center justify-between mb-4">
            <div>
              <a href="/" aria-label="Company" title="Company" class="inline-flex items-center">
                <svg class="w-8 text-deep-purple-accent-400" viewBox="0 0 24 24" stroke-linejoin="round" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor" fill="none">
                  <rect x="3" y="1" width="7" height="12"></rect>
                  <rect x="3" y="17" width="7" height="6"></rect>
                  <rect x="14" y="1" width="7" height="6"></rect>
                  <rect x="14" y="11" width="7" height="12"></rect>
                </svg>
                <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">Company</span>
              </a>
            </div>
            <div>
              <button aria-label="Close Menu" title="Close Menu" class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <nav>
            <ul class="space-y-4">
              <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a></li>
              <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a></li>
              <li><a href="/" aria-label="Product pricing" title="Product pricing" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a></li>
              <li><a href="/" aria-label="About us" title="About us" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">About us</a></li>
              <li><a href="/" aria-label="Sign in" title="Sign in" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Sign in</a></li>
              <li>
                <a
                  href="/"
                  class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                  aria-label="Sign up"
                  title="Sign up"
                >
                  Sign up
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      -->
          </div>
        </div>
      </div>
      <!-- second header ends here --><img src="@/assets/images/Mobtogether.png"
        class="lg:-mt-16 hidden lg:block  px-9 w-fullpx-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
        alt="" />
      <!-- <img src="@/assets/images/Mobtogether.png" class="lg:-mt-16 lg:hidden  px-9 w-full px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8" alt="" /> -->
      <img src="@/assets/images/MockupAllDevices.png"
        class="lg:-mt-16 -mt-11 lg:hidden xl:hidden 2xl:hidden transition duration-0 hover:duration-150  px-2 w-full  py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
        alt="" />
      <div
        class="flex flex-col items-center justify-center max-w-2xl px-4 pt-16 mx-auto sm:max-w-xl md:max-w-2xl -mt-10 lg:pt-32 md:px-8">
        <p
          class="max-w-lg text-center mb-6 lg:-mt-96 font-sans text-3xl font-semi-bold text-teal-600 leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">


          Open Your Online Community
          For The Future
        </p>


        <p class="text-base text-gray-700 text-center text-teal-600 md:text-lg">
          Get your Online Dating, Social Media Network, E-commerce,<br>
          Driving Schools, Online Marketplaces etc. ready for the Next Level
        </p> <router-link to="/register"><label for="my-modal-3"
            class="btn mt-5 lg:hidden xl:hidden 2xl:hidden bg-teal-500 text-white hover:bg-teal-400 border-0 hover:text-white">Register
            Now</label></router-link>




      </div>

    </div>
    <!-- main section ends here -->
  </div>
  <div><img src="@/assets/images/MockupAllDevices.png"
      class="lg:-mt-16 hidden lg:block transition duration-0 hover:duration-150  px-9 w-fullpx-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8"
      alt="" /></div>
  <div>
    <p
      class="max-w-lg text-center text-xl mt-10 lg:mt-1 mb-6 font-sans text text-teal-500 lg:text-3xl sm:text-lg md:text-lg font-semi-bold leading-none tracking-tight text-gray-900  md:mx-auto">

      100% RESPONSIVE
    </p>
  </div>
  <div>
    <p
      class="max-w-lg text-center text-3xl mb-6 font-sans text text-teal-500 text-3xl font-semi-bold leading-none tracking-tight text-gray-900  md:mx-auto">

      ON ANY DEVICE
    </p>
  </div>



  <!-- grid section -->
  <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
    <div class="grid gap-8 lg:grid-cols-3 px-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
      <div class="overflow-hidden transition-shadow shadow-lg  duration-300 bg-transparent rounded ">
        <img src="@/assets/images/Mockup1.png" class="object-contain w-full h-64" alt="" />

      </div>
      <div class="overflow-hidden transition-shadow duration-300  shadow-lg bg-transparent rounded shadow-none">
        <img src="@/assets/images/Mockup2.png" class="object-contain w-full h-64" alt="" />

      </div>
      <div class="overflow-hidden transition-shadow duration-300  shadow-lg bg-transparent rounded shadow-none">
        <img src="@/assets/images/3.jpg" class="object-contain w-full h-64" alt="" />

      </div>
    </div>
  </div>
  <!-- end of grid section -->
  <!-- 2nd grid section -->
  <div class="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full mb-16 lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
    <div class="grid gap-8 lg:grid-cols-3 px-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
      <div class="overflow-hidden transition-shadow  shadow-lg duration-300 bg-transparent rounded shadow-none">
        <img src="@/assets/images/Mockup4.png" class="object-contain w-full h-64" alt="" />

      </div>
      <div class="overflow-hidden transition-shadow duration-300  shadow-lg bg-transparent rounded shadow-none">
        <img src="@/assets/images/1.jpg" class="object-contain w-full h-64" alt="" />

      </div>
      <div class="overflow-hidden transition-shadow duration-300  shadow-lg bg-transparent rounded shadow-none">
        <img src="@/assets/images/Mockup6.png" class="object-contain w-full h-64" alt="" />

      </div>
    </div>
  </div>
  <!-- end of 2nd grid section -->


  <!-- start of the lady img section -->
  <div class="relative ">
    <img src="@/assets/images/background.jpg" class="absolute inset-0 object-cover w-full h-full" alt="" />
    <img src="@/assets/images/Girl.jpg" class="absolute lg:pl-96 items-end inset-0 object-contain w-full h-full" alt="" />
    <div class="relative bg-gray-900 bg-opacity-0">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="flex flex-col items-center justify-between xl:flex-row">
          <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
            <p
              class="max-w-lg text-lg mb-6 font-sans lg:text-3xl md:text-xl  font-bold tracking-tight text-teal-500 sm:text-4xl sm:leading-none">
              START YOUR COMMUNITY
              ENTERTAINMENT
              CHAPTER TODAY
            </p>
            <p class="max-w-xl text-2sm mb-4 font-semi-bold text-teal-400 md:text-sm">
              With MAZU we connect Entertainment and Community functions to
              a better, multi vision platform. You can offer your clients a combination
              of different booking tools (hotel, cinema, concerts, restaurants, cars etc.),
              dating components and marketplaces in only one platform.
            </p>
            <li><router-link to="/register"><label for="my-modal-3"
                  class="btn bg-teal-500 text-white hover:bg-teal-400 border-0 hover:text-white">Register
                  Now</label></router-link>

              <!-- Put this part before </body> tag -->
              <input type="checkbox" id="my-modal-3" class="modal-toggle" />
              <div class="modal">
                <div class="modal-box relative">
                  <label for="my-modal-3" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                  <div class="px-5 pt-6 pb-5 text-center border border-gray-300 rounded lg:w-full">
                    <div class="mb-5 font-semibold text-2xl">Create an account</div>
                    <div class="flex justify-center grid grid-cols-2 w-full mb-3 ">
                      <div><a href="/"
                          class="inline-flex items-center bg-teal-500 justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                          <div class="flex items-center">
                            <div class="mr-3 font-semibold text-white ">Buyer</div>

                          </div>
                        </a></div>
                      <div><a href="/"
                          class="inline-flex items-center bg-teal-500 justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none">
                          <div class="flex items-center">
                            <div class="mr-3 font-semibold text-white ">Seller</div>

                          </div>
                        </a></div>
                    </div>
                    <!-- <p class="max-w-md px-5 mb-3 text-xs text-gray-600 sm:text-sm md:mb-5">
        Sed ut unde omnis iste natus accusantium doloremque laudantium omnis iste.
      </p> -->
                    <!-- <div class="flex items-center w-full mb-5">
        <hr class="flex-1 border-gray-300" />
        <div class="px-3 text-xs text-gray-500 sm:text-sm">or</div>
        <hr class="flex-1 border-gray-300" />
      </div> -->
                  <!-- <a href="/" class="inline-flex items-center justify-center w-full h-12 px-6 font-semibold transition duration-200 bg-white border border-gray-300 rounded md:w-auto hover:bg-gray-100 focus:shadow-outline focus:outline-none">
        Sign Up with Email
      </a> -->
                </div>
              </div>
            </div>
          </li>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- end of the lady img section --></template>

<script>
export default {}
</script>