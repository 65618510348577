<template>
<section class="text-gray-700 body-font overflow-hidden bg-white">
  <div class="container px-5 py-24 mx-auto">
    <div class="lg:w-4/5 mx-auto flex flex-wrap">
      <img alt="ecommerce" class="lg:w-1/2 w-full h-96 object-cover object-center rounded border border-gray-200" :src="getProductUrl(productData)">
      <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h2 class="text-sm title-font text-gray-500 tracking-widest">MAZU - {{productData?.productType?.toLocaleUpperCase()}}</h2>
        <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">{{productData.name}}</h1>
        <div class="flex mb-4">
          <span class="flex items-center">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <span class="text-gray-600 ml-3">4 Reviews</span>
          </span>
          <span class="flex ml-3 pl-3 py-2 border-l-2 border-gray-200">
            <a class="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a class="ml-2 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a class="ml-2 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
              </svg>
            </a>
          </span>
        </div>
        <p class="leading-relaxed">{{productData.description}}</p>
        <div class="flex mt-6 items-center pb-12 border-b-2 border-gray-200 mb-5">
          
          <div v-if="productData.available_to != 'free'" class="flex ml-6 items-center">
            <span class="mr-3">Purchase Type</span>
            <div class="relative">
              <select v-model="priceSelect"  class="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-red-500 text-base pl-3 pr-10">
                <option value="rent" v-if="productData.available_to == 'rent' || productData.available_to == 'both'">Rent</option>
                <option value="buy" v-if="productData.available_to == 'buy' || productData.available_to == 'both'">Buy</option>
              </select>
              <span class="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4" viewBox="0 0 24 24">
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </div>
          </div>
          <div v-else>
              <h2 v-if="loggedIn" style="color:#EF4445">This is a free MAZU product click the button to avail now !!</h2>
          </div>
        </div>
        <div class="flex ">
          <span v-if="productData.available_to != 'free'" class="title-font font-medium text-2xl text-gray-900 ">${{productPrice}}</span>
          <a v-if="loggedIn" href="#" @click.prevent="getPaymentLink" class="flex ml-auto text-white  border-0 py-2 px-6 focus:outline-none  rounded" :class="{'bg-red-500 hover:bg-red-600':!isProductActivated,'bg-green-500':isProductActivated}" >{{componenetMeta.buttonText}}</a>
          <button class="rounded-full w-10 h-10 bg-gray-200 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-4 ">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
              <path d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="max-w-xl mb-5 md:mx-auto sm:text-center lg:max-w-2xl md:mb-2 mt-12">
  <p class="font-sans text-2xl font-bold text-gray-900 text-center">Gallery</p>
</div>
    <!-- <div class="lg:w-4/5 mx-auto flex flex-wrap image-gallery"> -->
    <section ref="imageGallery" id="image-gallery" class="overflow-hidden text-gray-700">
      <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
        <div class="flex flex-wrap -m-1 md:-m-2">
        <vue-picture-swipe :options="{ 
           bgOpacity: 0.7,
           wheelToZoom: true
          }" :items="gallery"></vue-picture-swipe>
          <!-- <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp">
            </div>
          </div>

          <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp">
            </div>
          </div> -->

           <!-- <div v-for="image in gallery" :key="image" class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                :src="getUrl(image)">
            </div>
          </div> -->
          <!-- <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp">
            </div>
          </div>
          <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp">
            </div>
          </div>
          <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp">
            </div>
          </div>
          <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(76).webp">
            </div>
          </div>
          <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp">
            </div>
          </div>
           <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp">
            </div>
          </div>
           <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp">
            </div>
          </div>
           <div class="flex flex-wrap w-1/3">
            <div class="w-full p-1 md:p-2">
              <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp">
            </div>
          </div> -->
        </div>
      </div>
    </section>
  <!-- </div> -->
  </div>
  <!-- show gallery here  -->

</section>
</template>

<script>
 import VuePictureSwipe from 'vue3-picture-swipe';
import helperMixin from '@/mixins/helper';
export default {
  components:{
    VuePictureSwipe
  },
  props:['loggedIn','isProductActivated'],
  mixins:[helperMixin],
  data() {
    return {
      productData:{},
      priceSelect:'buy',
      gallery: []
    }
  },
  async created() {
    let productType = this.$route.query.productType;
    let { data } = await this.axios.get(`shop/${productType}/${this.$route.params.id}`, {
      loadingMesssage: "Getting Data"
    })
    this.productData = data.data
    this.setGallery()
    console.log(this.gallery)
    this.priceSelect = this.productData.available_to == 'both' ? 'buy' : this.productData.available_to
  },
  methods:{
    async activateFreeProduct() {
      if(this.isProductActivated) return this.$notify({ title:"Message",type: "success", text: "Product already activated" });
      try{
      let { data } = await this.axios.post('user/accounts/free-product-purchase',{
        accountId:this.$store.state.core.activeAccount.id,
        productId:this.productData.id,
        productType:this.$route.query.productType,
      },{
          loadingMesssage:"Loading"
      })
      this.$notify({ title:"Message",type: "success", text: "Product activated successfully" });
      this.$router.push('/dashboard/shop')
      // let url = data.url
      // window.location.href = url
      // console.log(url)
      }catch(e) {
        // let { data : { message } } = e.response
        // this.error = message
        console.log(e)

        this.$notify({ title: "Error", text: this.error });
      }
    },
    async getPaymentLink() {
    try{
        if(this.isProductActivated) return this.$notify({ title:"Message",type: "success", text: "Product already activated" });
        if(this.productData.available_to == 'free') {
          await this.activateFreeProduct()
          return
        }
        let { data } = await this.axios.post('user/accounts/purchase-product',{
          accountId:this.$store.state.core.activeAccount.id,
          productId:this.productData.id,
          productType:this.$route.query.productType,
          purchaseType:this.priceSelect,
          currency:'usd'
        },{
            loadingMesssage:"Getting payment Link .."
        })
        let url = data.url
        window.location.href = url
        console.log(url)
      }catch(e) {
        // let { data : { message } } = e.response
        // this.error = message
        console.log(e)
        this.$notify({ title: "Error", text: this.error });
      }
    },
    setGallery() {
      let gallery;
      if(this.productData.extension_gallery) {
        this.gallery = this.productData.extension_gallery.split(",")
      }
      if(this.productData.plugin_gallery) {
        this.gallery = this.productData.plugin_gallery.split(",")
      }
      if(this.productData.theme_gallery) {
        this.gallery = this.productData.theme_gallery.split(",")
      }
      this.gallery = this.gallery.map((image)=>{
        return {
          src: this.getUrl(image),
          thumbnail: this.getUrl(image),
          w: 1200,
          h: 950,
        }
      })
    },
  },
  computed:{
    componenetMeta:function() {
      return {
        buttonText: this.isProductActivated ? 'Activated' : 'Activate' 
      }
    },
    productPrice:function() {
      if(this.productData.Prices?.usd) {
         return this.productData.Prices.usd.find(p => p.purchaseType == this.priceSelect).price
       }else{
        return null
      }
    },
  }
}
</script>

<style>
.my-gallery{ 

  display: flex;
  font-size: 0;
 flex-flow: row wrap;
  
}
.gallery-thumbnail {
 
  
  
  height: 300px;
  width:32%;
 border:solid 2px black
}
.my-gallery img {
  height:100%;
  width:100%;
  /* margin-left:1rem */
}
</style>