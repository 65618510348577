<style>
  #more {
  display: none;
  }
</style>
<template>
  <div>
    <!-- component -->
    <!-- ====== Pricing Section Start -->
    <section class="
      px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8
      ">
      <div class="container">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full px-4">
            <div class="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">
              <!-- <span class="font-semibold text-lg text-primary mb-2 block">
                Pricing Table
                </span> -->
              <h2 class="
                font-bold
                text-5xl
                sm:text-4xl
                md:text-[40px]
                text-dark
                mb-4 text-teal-500
                ">
                PACKAGES
              </h2>
              <!-- <p class="text-base text-body-color">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
                </p> -->
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-center -mx-4">
          <div class="w-full md:w-1/2 lg:w-1/3 px-4">
            <div class="
              bg-white
              rounded-xl
              relative
              z-10
              overflow-hidden
              border border-teal-500 border-opacity-20
              shadow-pricing
              py-10
              px-8
              sm:p-12
              lg:py-10 lg:px-6
              xl:p-12
              mb-10
              ">
              <span class="text-teal-600 font-semibold text-center text-4xl block mb-4">
              Rise
              </span>
              <h2 class="font-bold text-center text-dark mb-5 text-[42px] text-teal-500">
                $59
                <span class="text-base text-body-color font-medium">
                / year
                </span>
              </h2>
              <p class="
                text-base text-body-color
                pb-8
                mb-8 
                text-teal-900 text-center font-bold uppercase
                ">
                MAZU Community <br>
                Rise Edition
              </p>
              <div class="mb-7">
                <p class="text-base text-body-color leading-loose mb-1">
                  1. MAZU Control Panel
                  (Admin and Moderation)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  2. Premium themes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  3. free payment Methods
                  (Paypal, Stripe)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  4. 2000 Dummy Accounts
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  5. Turnover/ Earnings
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  6. Promotion (Spam,
                  Likes, Pokes)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  7. Statistics and Overviews
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  8. Moderation Access,
                  Team Access
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  9. Monthly hosting and service
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  10. Matching, likes and dislikes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  11. Affiliate System
                </p>
              </div>
              <a href="javascript:void(0)" class="
                w-full
                block
                text-base
                font-semibold
                bg-transparent
                border border-teal-500
                rounded-md
                text-center
                p-4
                hover:text-white hover:bg-primary hover:border-primary
                transition text-teal-500
                ">
              Select Now
              </a>
              <div>
                <span class="absolute right-0 top-7 z-[-1]">
                  <svg width="77" height="172" viewBox="0 0 77 172" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient id="paint0_linear" x1="86" y1="0" x2="86" y2="172"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3056D3" stop-opacity="0.09" />
                        <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-4">
            <div class="
              bg-white
              rounded-xl
              relative
              z-10
              overflow-hidden
              border border-teal-500 border-opacity-20
              shadow-pricing
              py-10
              px-8
              sm:p-12
              lg:py-10 lg:px-6
              xl:p-12
              mb-10
              ">
              <span class=" text-center font-semibold  block mb-4 text-teal-600 text-4xl">
              Evolve
              </span>
              <h2 class="font-bold text-center text-dark mb-5 text-[42px] text-teal-500">
                $199
                <span class="text-base text-body-color font-medium">
                / year
                </span>
              </h2>
              <p class="
                text-base text-body-color
                pb-8
                mb-8 
                text-teal-900 text-center font-bold
                ">
                MAZU Community <br>
                Evolve Edition
              </p>
              <div class="mb-7">
                <p class="text-base text-body-color leading-loose mb-1">
                  1. MAZU Control Panel
                  (Admin and Moderation)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  2. 3 Premium themes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  3. free payment Methods
                  (Paypal, Stripe)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  4. 5000 Dummy Accounts
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  5. Turnover/ Earnings
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  6. Promotion
                  (Spam, Likes, Pokes)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  7. Statistics and Overviews
                  (Spam, Likes, Pokes)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  8. Moderation Access,
                  Team Access
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  9. Monthly hosting and service
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  10. Matching, likes and dislikes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  11. Affiliate System
                </p>
                <span id="dots">...</span>
                <p>
                  <span id="more">
                <p class="text-base text-body-color leading-loose mb-1">
                6. Promotion
                (Spam, Likes, Pokes)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                13. 1 Monthly Newsletter
                Promotion (1 mio dating
                clients USA, CAN, AUS)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                14. Client Pools
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                15. Product Packages
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                16. Coupon Codes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                17. Events
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                18. Live Monitoring
                </p>
                </span><button onclick="myFunction()" id="myBtn">Read more</button>
                </p>
              </div>
              <a href="javascript:void(0)" class="
                w-full
                block
                text-base
                font-semibold
                text-white
                border border-none
                rounded-md
                text-center
                bg-teal-500
                p-4
                hover:bg-opacity-90
                transition
                ">
              Select Now
              </a>
              <div>
                <span class="absolute right-0 top-7 z-[-1]">
                  <svg width="77" height="172" viewBox="0 0 77 172" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient id="paint0_linear" x1="86" y1="0" x2="86" y2="172"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3056D3" stop-opacity="0.09" />
                        <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 lg:w-1/3 px-4">
            <div class="
              bg-white
              rounded-xl
              relative
              z-10
              overflow-hidden
              border border-teal-500 border-opacity-20
              shadow-pricing
              py-10
              px-8
              sm:p-12
              lg:py-10 lg:px-6
              xl:p-12
              mb-10
              ">
              <span class=" text-center font-semibold block mb-4 text-teal-600 text-4xl">
              Beyond
              </span>
              <h2 class="font-bold text-center text-dark mb-5 text-[42px] text-teal-500">
                $256
                <span class="text-base text-body-color font-medium">
                / year
                </span>
              </h2>
              <p class="
                text-base text-body-color
                pb-8
                mb-8 
                text-teal-900 text-center font-bold
                ">
                MAZU Community <br>
                Beyond Edition
              </p>
              <div class="mb-7">
                <p class="text-base text-body-color leading-loose mb-1">
                  1. MAZU Control Panel
                  (Admin and Moderation)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  2. 3 Premium themes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  3. free payment Methods
                  (Paypal, Stripe)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  4. 5000 Dummy Accounts
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  5. Turnover/ Earnings
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  6. Promotion
                  (Spam, Likes, Pokes)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  7. Statistics and Overviews
                  (Spam, Likes, Pokes)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  8. Moderation Access,
                  Team Access
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                  9. Monthly hosting and service
                </p>
                <span id="dots">...</span>
                <p>
                  <span id="more">
                <p class="text-base text-body-color leading-loose mb-1">
                10. Matching, likes and dislikes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                11. Affiliate System
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                6. Promotion
                (Spam, Likes, Pokes)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                13. 1 Monthly Newsletter
                Promotion (1 mio dating
                clients USA, CAN, AUS)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                14. Client Pools
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                15. Product Packages
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                16. Coupon Codes
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                17. Events
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                18. Live Monitoring
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                19. 1 monthly Online Promotion
                (ABC, NBC, FOX, CBS, CW
                affiliate sites, Google news,
                moody´s etc.)
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                20. Send virtual gifts
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                21. Send nude pictures
                </p>
                <p class="text-base text-body-color leading-loose mb-1">
                22. Nude content filter
                </p>
                </span><button onclick="myFunction()" id="myBtn">Read more</button>
                </p>
              </div>
              <a href="javascript:void(0)" class="
                w-full
                block
                text-base
                font-semibold
                bg-transparent
                border border-teal-500
                rounded-md
                text-center
                p-4
                hover:text-white hover:bg-primary hover:border-primary
                transition text-teal-500
                ">
              Select Now
              </a>
              <div>
                <span class="absolute right-0 top-7 z-[-1]">
                  <svg width="77" height="172" viewBox="0 0 77 172" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient id="paint0_linear" x1="86" y1="0" x2="86" y2="172"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3056D3" stop-opacity="0.09" />
                        <stop offset="1" stop-color="#C4C4C4" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== Pricing Section End -->
    <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
        </div>
        <h2
          class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Why Chooose MAZU?
        </h2>
        <p class="text-base text-gray-700 md:text-lg">
          Description Goes here Description Goes here
          Description Goes here Description Goes hereDescription Goes
          here Description Goes here Description Goes here 
        </p>
      </div>
    </div>
  </div>
  <div class="bg-teal-100">
    <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8
      ">
      <div class="px-4 py-16 w-full mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div class="flex flex-col justify-center">
            <div class="max-w-xl mb-6">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                100%
                <span class="relative px-1">
                  <div class="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400">
                  </div>
                  <span class="relative inline-block text-xl text-teal-400">Responsive</span>
                </span>
              </h2>
              <h2
                class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                100%
                <span class="relative px-1">
                  <div class="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400">
                  </div>
                  <span class="relative inline-block text-xl text-teal-400">Responsive</span>
                </span>
              </h2>
              <h2
                class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                100%
                <span class="relative px-1">
                  <div class="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400">
                  </div>
                  <span class="relative inline-block text-xl  text-teal-400">Responsive</span>
                </span>
              </h2>
              <p class="text-base text-gray-700 md:text-lg">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                laudantium, totam rem aperiam, eaque ipsa quae. explicabo.
              </p>
            </div>
          </div>
          <div>
            <img class="object-cover w-full h-56 rounded  sm:h-96"
              src="@/assets/images/photo.png"
              alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="
    px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8
    ">
    <div class="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0 shadow-2xl rounded-2xl">
      <div class="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
        <svg class="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block" viewBox="0 0 100 100" fill="currentColor" preserveAspectRatio="none slice">
          <path d="M50 0H100L50 100H0L50 0Z"></path>
        </svg>
        <img
          class="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
          alt=""
          />
      </div>
      <div class="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div class="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
          <h2 class="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Have Questions?
          </h2>
          <p class="pr-5 mb-5 text-base text-gray-700 md:text-lg">
            Description Goes here Description Goes here
            Description Goes here Description Goes here Description Go 
          </p>
          <div class="flex items-center">
            <a
              href="/"
              class="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-teal-500 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
            Get started
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  function myFunction() {
      var dots = document.getElementById("dots");
      var moreText = document.getElementById("more");
      var btnText = document.getElementById("myBtn");
  
      if (dots.style.display === "none") {
          dots.style.display = "inline";
          btnText.innerHTML = "Read more";
          moreText.style.display = "none";
      } else {
          dots.style.display = "none";
          btnText.innerHTML = "Read less";
          moreText.style.display = "inline";
      }
  }
</script>