<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">MAZU Account</span>
        </div>
      </div>
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="grid gap-5 row-gap-8 lg:grid-cols-2">
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none">
                  Mazu Accounts
                </h2>
                <p class="text-base text-gray-700 md:text-sm">
                  Following are the mazu versions you have registered</p>
              </div>

            </div>
            <div class="items-end justify-end lg:pl-96 ">
              <img
                class="object-cover w-full h-full rounded shadow-sm lg:h-36 lg:w-36 sm:h-49 sm:w-96 items-end justify-end"
                src="@/assets/images/login/Manufacturerlogo.jpg" alt="" />
            </div>
          </div>
        </div>


      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64" v-if="accounts.length> 0">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         <div class="grid gap-5 row-gap-8 lg:grid-cols-2">
            <div v-for="(account , index) in accounts" :key="index" class="p-8 rounded shadow-xl sm:p-16 bg-white"
            :class="{ 'active-account' : account.id == $store.state.core.activeAccount.id }"
            >
              <div class="flex flex-col lg:flex-row">
                <div class="lg:w-24"><img src="/img/Shopownerslogo.6a2c614c.jpg" alt=""></div>
                <div class="mb-6 lg:mb-0 px-5 lg:w-full lg:pr-5">
                  <h2 class="font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none"> {{ account.account_name}}</h2>
                  <div v-if="account.Mazu != null" class="mt-2 "> MAZU - {{account.Mazu.name}} </div>
                  <p class="mb-4 text-sm py-5 text-gray-700"> {{ account.account_description }} </p>
                  <!-- button -->
                  <div class="grid grid-cols-2 gap-4 w-56">
                    <div v-if="!account.is_paid"  class=""><router-link :to="{name: 'MazuPayment', params: { id: account.id }}" class="btn border-teal-500 text-gray-800 hover:bg-teal-300 hover:text-white hover:border-white">Activate</router-link></div>
                    <!-- <div class=""><button class="btn border-teal-500 text-gray-800 hover:bg-teal-300 hover:text-white hover:border-white">View</button></div> -->
                    <div class="">
                      <button   @click="selectAccount(account.id)" v-if="account.id == $store.state.core.activeAccount.id == false" class="btn border-teal-500 text-gray-800 hover:bg-teal-300 hover:text-white hover:border-white">Select</button>
                    </div>
                  </div>
                  <!-- button -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         <div class="grid gap-5 row-gap-8 lg:grid-cols-2">
            No Account found (Please click link below to register)
          </div>
        </div>
      </div>

      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div>
            <router-link to="/dashboard/register"> <p class=" w-full btn bg-green-500 border-white text-xs text-white">Register for MAZU</p></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import helperMixin from '@/mixins/helper';

export default {
  mixins: [helperMixin],
  data() {
    return {
      accounts: []
    }
  },
  async created() {
    let { data } = await this.axios.get('user/accounts',{
        loadingMesssage:"Getting Accounts .."
      })
      this.accounts = data.data
  },
  methods:{
    activateAccount(data) {
      console.log(data)
    }
  }
}
</script>

<style>
.active-account{
  border-top:solid 4px #29B8A6;
  color:#29B8A6
}
</style>