<template>
  <div class="h-full bg-teal-50">
        <div class="flex items-center  lg:-mt-10  px-5 " >
          <div class=" mt-12 pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Website Logo</h1>
            </div>
            <div class="px-3">
              <img :src="logo" class="mb-4" style="height:150px">
            <input
            type="file"
            ref="logo"
            placeholder ="Stripe Secret"
            class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
            <button
            @click.prevent="updateLogo()"
              class=" mt-4 hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              >
            Update Logo
            </button>
            </div>
          </div>
        </div>
      </div>
      <div class="h-full bg-teal-50 mt-12">
        <div class="flex items-center  lg:-mt-10  px-5 " >
          <div class=" mt-12 pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">CTA box Top</h1>
            </div>
            <div class="px-3">
            <input
              type="text"
              v-model="cta_box.top.heading"
              placeholder ="Top Heading"
              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
             <input
              type="text"
              v-model="cta_box.top.subHeading"
              placeholder ="Sub Heading"
              class=" mt-8 w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
            <input
              type="text"
              v-model="cta_box.top.buttonText"
              placeholder ="Button Text"
              class=" mt-8 w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
            </div>
          </div>
        </div>
      </div>
      <div class="h-full bg-teal-50 mt-4">
        <div class="flex items-center  lg:-mt-10  px-5 " >
          <div class=" mt-12 pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">CTA box Bottom</h1>
            </div>
            <div class="px-3">
            <input
              type="text"
              v-model="cta_box.bottom.heading"
              placeholder ="Top Heading"
              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
             <input
              type="text"
              v-model="cta_box.bottom.subHeading"
              placeholder ="Sub Heading"
              class=" mt-8 w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
            <input
              type="text"
              v-model="cta_box.bottom.buttonText"
              placeholder ="Button Text"
              class=" mt-8 w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            />
            <button
            @click="updateCta()"
              class=" mt-4 hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              >
              Update CTA Boxex
            </button>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import helperMixin from '@/mixins/helper'
//user/activateTheme/711c500d-bb72-43c1-9bac-cc31c354ce4b
export default {
  mixins:[helperMixin],
  data() {
    return  {
      cta_box: {
        top:{
          heading:"",
          subHeading:"",
          buttonText:""
        },
        bottom:{
          heading:"",
          subHeading:"",
          buttonText:""
        },
      },
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png'
    }
  },
  async created() {
    try{
      let activeAccountId = this.$store.state.core.activeAccount.id
      let { data } = await this.axios.get(`user/dating-community-meta/${activeAccountId}`)
      // this.logo = data.data.logo
      if(data?.data?.logo) {
        this.logo = this.getUrl(data.data.logo)
      }
      if(data?.data?.cta_object){
        // alert("object found")
        this.cta_box = JSON.parse(data?.data?.cta_object)
      }
      console.log(data)
    }catch(e) {
      console.log(e)
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: this.error });
    }
  },
  methods: {
    async updateCta() {
      let postObject = {
        data : this.cta_box
      }
      let activeAccountId = this.$store.state.core.activeAccount.id
      try{
        await this.axios.post(`user/update_cta/${activeAccountId}`,postObject,{
          loadingMesssage:"Updating Boxes .."
        })
        // this.logo = this.getUrl(data.logo)
        this.$notify({ title:"Message",type: "success", text: "Cta Boxes Updated Successfully" });
        
      }catch(e) {
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
      console.log(JSON.stringify(cta_object) )
    },
    async updateLogo() {
      let file = this.$refs.logo.files[0]
      if(!file) return alert("Please select an logo file")
      var formData = new FormData();
       let activeAccountId = this.$store.state.core.activeAccount.id
       formData.append('files', file,'image');
       try{
        let { data } = await this.axios.post(`user/updateLogo/${activeAccountId}`,formData,{
          loadingMesssage:"Updating Logo .."
        })
        this.logo = this.getUrl(data.logo)
        this.$notify({ title:"Message",type: "success", text: "Logo Updated Successfully" });
        
      }catch(e) {
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    }
  }
}
</script>