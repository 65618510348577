<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">Extension Gallery</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                 Extension Gallery
                </h2>
             </div>
               <div class="">
                <!-- component -->
                <div class="flex items-center lg:-mt-10  px-5 justify-center p-12" >
                  <!-- Author: FormBold Team -->
                  <div class="mx-auto  lg:w-4/5  bg-white font-sans" >
                  <div class=" px-5 mt-12 mb-12" >
                     <div class="container flex flex-wrap mx-auto">
                        <div v-for="(file , index) in extensionGallery" :key="index" class="w-full p-2 rounded lg:w-1/3 md:w-1/2  relative group">
                          <img style="height:200px;width:250px" :src="getUrl(file)" alt="image"/>
                            <div class="opacity-0 group-hover:opacity-40 bg-teal-800  duration-300 absolute inset-0 flex justify-center items-center z-1000">
                                <a @click.prevent="removeGalleryImage(file)" href="#"><Icon icon="mdi-light:delete" style="color:red" height="24"/></a>
                            </div>
                        </div>
                      </div> 
                        <br>
                          <FileUpload
                          :multiple="true"
                          ref="fileupload"
                          buttonText= "Add Gallery images"
                          imagesText= "Extension Gallery"
                          fileDataKey="extensionGallery"
                          @dataOut="addExtensionGallery($event)"
                        ></FileUpload> 
                      <div>
                        <button
                        @click="addNewImages"
                          class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                          >
                        Add new Images
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '../../../components/FileUpload.vue'
import { Icon } from '@iconify/vue';
import helperMixin from '@/mixins/helper';

export default {
  mixins: [helperMixin],
  components:{
    FileUpload,
    Icon,
    
  },
  
  async mounted(){
     let { data } = await this.axios.get(`admin/theme/${this.$route.params.id}`,{
      loadingMesssage:"Getting Theme"
    })
    if(!data.data){
      alert('no data found')
    }
    data = data.data
    console.log(data)

   
    let extensionGallery = data.theme_gallery
    if(extensionGallery){
      extensionGallery = extensionGallery.split(",")
      this.extensionGallery = extensionGallery
    }
  console.log(data)
  




  },
   data(){
    return {
      id:this.$route.params.id,
      extensionGallery:[],
      newGalleryImages:[]
    }
  },
 
  methods:{
   
    setNewExtensionImage(data){
      console.log(this)
      this.updtaedExtenImage = Object.values(data)[0][0]
    },
    addExtensionGallery(data){
      this.newGalleryImages = Object.values(data)[0]
      console.log(this)
    },
    async addNewImages() { 
      if(!this.newGalleryImages.length)  return this.$notify({ title: "Error", text: 'Please select images' });
      var formData = new FormData();
      for(let i = 0;i<this.newGalleryImages.length;i++){
          formData.append('files', this.newGalleryImages[i],`theme_gallery`);
      }
      try{
         let { data } = await this.axios.post(`admin/theme/gallery/add/${this.$route.params.id}`,formData,{
          loadingMesssage:"Adding Images .."
         })
         let newGallery = data.data
         this.extensionGallery = newGallery
        this.$refs.fileupload.reset()
         this.newGalleryImages = []

      }catch(e){
         let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    
    },
    async removeGalleryImage(file) {
      let result = await this.$swal({
        title: "Delete this image?",
        text: "Are you sure? You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!"
      })
      if(!result.isConfirmed) return
      try{
        await this.axios.post(`admin/theme/gallery/delete/${this.$route.params.id}`,{
          name:file
        },{
          loadingMesssage:"Deleteing Image .."
        })
        this.extensionGallery = this.extensionGallery.filter(i => i != file) 
        this.$notify({ title:"Message",type: "success", text: "Image deleted successfully" });

      }catch(e){
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
      
    }
  },
}

// let { data } = await this.axios.post('user/login',{
//           userNameEmail:this.usernameEmail,
//           password:this.password
//         },{
//           loadingMesssage:"Logging in .."
//         })
</script>

<style>
input:disabled {
  background: #dddddd;
  border:#dddddd;
}
.opaque{
  opacity: 0.1 !important;
}
</style>