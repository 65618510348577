import store from '../store'
import router from '../router'
import env from '../env'
import { useLoading } from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';



export const authGuard = (to, from, next) => {
  const isLoggedIn = store.state.core.loggedIn
  if (isLoggedIn === false) {
    router.push("/login")
  } else { 
    next()
  }
};

export const adminAuthGuard = (to, from, next) => {
  const isLoggedIn = store.state.core.adminloggedIn
  if (isLoggedIn === false) {
    router.push("/mazu-login")
  } else { 
    next()
  }
};

export const paidAccountGuard = (to, from, next) => {
  let activeAccount =store.state.core.activeAccount 
  if (activeAccount.is_paid === false) {
    router.push(`dashboard/payment/${activeAccount.id}`)
  } else { 
    next()
  }
};


export const activeAccountGuard = (to, from, next) =>{
  let activeAccount =store.state.core.activeAccount 
  if (!activeAccount.id) {
    router.push(`dashboard/register`)
  } else { 
    next()
  }
}
export const loader = {
  loader : useLoading(),
  show:function() {
    this.loader.show({
      color: '#18AB00',
      loader: 'bars',
      backgroundColor: 'black',
      opacity: 0.5,
      zIndex: 999,
    });
  },
  hide:function() {
    this.loader.hide()

  }
} 


export const categories = [
  {
    name:"Themes",
    slug:"themes"
  },
  {
    name:"Marketing and Promotion",
    slug:"marketing_promotion"
  },
  {
    name:"Mobile and Responsive",
    slug:"mobile_responsive"
  },
  {
    name:"Backend",
    slug:"backend"
  }
]