<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <!-- <img class="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg" /> -->
          <span class="  text-xl">MAZU</span>
        </div>
      </div>
      <AdminSidebar></AdminSidebar>
      <vue-progress-bar></vue-progress-bar>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AdminSidebar from '../../components/Admin/Sidebar/AdminSidebar.vue'
export default {
  components:{
    AdminSidebar
  }
}
</script>