<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">Add Admin</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                 Add Admin
                </h2>
             </div>
               <div class="">
                <!-- component -->
                <div class="flex items-center lg:-mt-10  px-5 justify-center p-12" >
                  <!-- Author: FormBold Team -->
                  <div class="mx-auto  lg:w-4/5  bg-white font-sans" >
                  <div class=" px-5 mt-12 mb-12" >
                      <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             First Name
                            </label>
                            <input
                              type="text"
                              v-model="first_name"
                              placeholder ="First Name"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                              <div class="input-errors" v-for="error of v$.first_name.$errors" :key="error.$uid">
                               <span v-if="v$.first_name.$error" class="form-error">{{ error.$message }}</span>
                              </div>
                          </div>
                        </div>
                         
                      </div>
                      <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             Last Name
                            </label>
                            <input
                              type="text"
                              v-model="last_name"
                              placeholder ="Last Name"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                              <div class="input-errors" v-for="error of v$.last_name.$errors" :key="error.$uid">
                               <span v-if="v$.last_name.$error" class="form-error">{{ error.$message }}</span>
                              </div>
                          </div>
                        </div>
                         
                      </div>
                      <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             User Name
                            </label>
                            <input
                              type="text"
                              v-model="username"
                              placeholder ="Username"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                              <div class="input-errors" v-for="error of v$.username.$errors" :key="error.$uid">
                               <span v-if="v$.username.$error" class="form-error">{{ error.$message }}</span>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             Email
                            </label>
                            <input
                              type="email"
                              v-model="email"
                              placeholder ="Email"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                              <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                               <span v-if="v$.email.$error" class="form-error">{{ error.$message }}</span>
                              </div>
                          </div>
                        </div>
                      </div>
                       <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             Password
                            </label>
                            <input
                              type="text"
                              v-model="password"
                              placeholder ="Password"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                              <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                               <span v-if="v$.password.$error" class="form-error">{{ error.$message }}</span>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             Role
                            </label>
                            <select
                            v-model="role"
                            class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                            >
                            <!-- <option value="">Select role</option> -->
                              <option value="MAZU_ADMIN">MAZU ADMIN</option>
                              <option value="MAZU_SERVER_ADMIN">MAZU SERVER ADMIN</option>
                              <option value="MAZU_DEVELOPER">MAZU DEVELOPER</option>

                            </select>
                            <!-- <input
                              type="text"
                              v-model="name"
                              placeholder ="Name"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              /> -->
                              <div class="input-errors" v-for="error of v$.role.$errors" :key="error.$uid">
                               <span v-if="v$.role.$error" class="form-error">{{ error.$message }}</span>
                              </div>
                          </div>
                        </div>
                      </div>
                       <br>
                      <div>
                        <button
                        @click="addAdmin"
                          class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                          >
                        Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required , minLength , minValue ,requiredIf } from '@vuelidate/validators'
import FileUpload from '@/components/FileUpload.vue'
export default {
  components:{
    FileUpload
  },
   setup () {
    return { v$: useVuelidate() }
  },
   data(){
    return {
     first_name:"",
     last_name:"",
     username:"",
     email:"",
     password:"",
     role:"",
    }
  },
  validations () {
    return {
      first_name: { required },
      last_name: { required },
      email: { required },
      username: { required ,minLength:minLength(5)},
      password: { required ,minLength:minLength(8)},
      role : { required }
    }
  },
  methods:{
    async addAdmin() {
      
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect) return
      
      try{
        let { data } = await this.axios.post('admin/backend/register',{
          first_name: this.first_name,
          last_name: this.last_name,
          username: this.username,
          password: this.password,
          email:this.email,
          role:this.role,
        },{
          loadingMesssage:"Loading .."
        })
        this.$notify({ title:"Message",type: "success", text: "Mazu Added Successfully" });
        console.log(data)
        this.$router.push('/admin/admins')
      }catch(e) {
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    },
  }
}
</script>

<style>
input:disabled {
  background: #dddddd;
  border:#dddddd;
}
</style>