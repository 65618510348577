<template>
    <UpdateProduct
    productType = 'plugin'
    />
</template>

<script>
import  UpdateProduct  from "@/components/Admin/UpdateProduct.vue"
export default {
  components:{
    UpdateProduct
  }
}
</script>