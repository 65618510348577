<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">Update {{meta.name}}</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                 Update {{meta.name}}
                </h2>
             </div>
               <div class="">
                <!-- component -->
                <div class="flex items-center lg:-mt-10  px-5 justify-center p-12" >
                  <!-- Author: FormBold Team -->
                  <div class="mx-auto  lg:w-4/5  bg-white font-sans" >
                  <div class=" px-5 mt-12 mb-12" >
                      <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             Name
                            </label>
                            <input
                              type="text"
                              v-model="name"
                              placeholder="Name"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                              <div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                               <span v-if="v$.name.$error" class="form-error">{{ error.$message }}</span>
                              </div>
                          </div>
                        </div>
                         
                      </div>
                       <div class="mb-5">
                          <label class="mb-3 block text-base font-medium text-gray-700 font-sans">
                        <div>
                        <h1 class="mb-3 block text-base font-medium text-teal-500 font-sans">Available for</h1>
                          </div>
                            </label>
                            <div class="flex items-center space-x-6">
                              <div class="flex items-center">
                                <input
                                  type="radio"
                                  name="radio2"
                                  v-model="availableTo"
                                  class="  h-5 w-5"
                                  value="rent"
                                />
                                <label
                                  for="radioButton1"
                                  class="pl-3 text-base font-medium text-[#07074D]"
                                >
                                  Rent
                                </label>
                              </div>
                              <div class="flex items-center">
                                <input
                                  type="radio"
                                  name="radio2"
                                  v-model="availableTo"
                                  value="buy"
                                  class="h-5 w-5"
                                />
                                <label
                                  for="radioButton2"
                                  class="pl-3 text-base font-medium text-[#07074D]"
                                >
                                  Buy
                                </label>
                              </div>
                              <div class="flex items-center">
                                <input
                                  type="radio"
                                  name="radio2"
                                  v-model="availableTo"
                                  value="both"
                                  class="h-5 w-5"
                                />
                                <label
                                  for="radioButton2"
                                  class="pl-3 text-base font-medium text-[#07074D]"
                                >
                                  Both
                                </label>
                              </div>
                              <div class="flex items-center">
                                <input
                                  type="radio"
                                  name="radio2"
                                  v-model="availableTo"
                                  value="free"
                                  class="h-5 w-5"
                                />
                                <label
                                  for="radioButton2"
                                  class="pl-3 text-base font-medium text-[#07074D]"
                                >
                                  Free
                                </label>
                              </div>
                            </div>
                        </div>
                        <div class="mb-5">
                          <label class="mb-3 block text-base font-medium text-gray-700 font-sans">
                        <div>
                        <h1 class="mb-3 block text-base font-medium text-teal-500 font-sans">Mazu Product</h1>
                          </div>
                            </label>
                            <div class="flex items-center space-x-6">
                              <div class="flex items-center">
                                <input
                                  type="radio"
                                  name="radio1"
                                  v-model="mazuProduct"
                                  class="  h-5 w-5"
                                  value="flirttool"
                                />
                                <label
                                  for="radioButton1"
                                  class="pl-3 text-base font-medium text-[#07074D]"
                                >
                                  Flirtool
                                </label>
                              </div>
                              <div class="flex items-center">
                                <input
                                  type="radio"
                                  name="radio1"
                                  v-model="mazuProduct"
                                  value="mazupanel"
                                  class="h-5 w-5"
                                />
                                <label
                                  for="radioButton2"
                                  class="pl-3 text-base font-medium text-[#07074D]"
                                >
                                  Mazupanel
                                </label>
                              </div>
                            </div>
                        </div>
                         <div class="mb-5">
                          <label class="mb-3 block text-base font-medium text-gray-700 font-sans">
                        <div>
                        <h1 class="mb-3 block text-base font-medium text-teal-500 font-sans">Category</h1>
                          </div>
                            </label>
                            <div class="flex items-center space-x-6">
                              <div v-for ="(cat,index) in categories" :key="index" class="flex items-center">
                                <input
                                  type="radio"
                                  name="radio5"
                                  v-model="category"
                                  class="  h-5 w-5"
                                  :value="cat.slug"
                                />
                                <label
                                  for="radioButton1"
                                  class="pl-3 text-base font-medium text-[#07074D]"
                                >
                                  {{ cat.name }}
                                </label>
                              </div>
                            </div>
                        </div>
                     <div class="-mx-3 flex flex-wrap">
                        <div class="w-full px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                            Description
                            </label>
                            <textarea
                            v-model="description"
                              placeholder="Description"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              ></textarea>
                               <div class="input-errors" v-for="error of v$.description.$errors" :key="error.$uid">
                               <span v-if="v$.description.$error" class="form-error">{{ error.$message }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <FileUpload
                          :multiple="false"
                          buttonText= "Change Image"
                          imagesText= "Updated Image"
                          fileDataKey="extensionImage"
                          @dataOut="setNewExtensionImage($event)"
                        ></FileUpload>
                      <div class="container flex flex-wrap mx-auto">
                        <img :class="{ 'opaque':updtaedExtenImage != null }" style="height:300px" :src="getUrl(extensionImage)" alt="image"/>
                      </div>
                      <div>
                        <button
                        @click="addExtension"
                          class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                          >
                        Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required , minLength , minValue ,requiredIf } from '@vuelidate/validators'
import FileUpload from '@/components/FileUpload.vue'
import helperMixin from '@/mixins/helper';
import { categories } from '@/utils/helpers'

export default {
  mixins: [helperMixin],
  components:{
    FileUpload,
  },
   setup () {
    return { v$: useVuelidate() }
  },
  props:['productType'],
  async mounted() {
    switch(this.productType) {
      case 'extension':
        this.meta.url = `admin/extension/${this.$route.params.id}`
        this.meta.successUrl = '/admin/extensions'
        this.meta.name = 'Extension'
        break;
      case 'plugin':
        this.meta.url = `admin/plugin/${this.$route.params.id}`
        this.meta.successUrl = '/admin/plugins'
        this.meta.name = 'Plugin'
        break;
      case 'theme':
        this.meta.url = `admin/theme/${this.$route.params.id}`
        this.meta.successUrl = '/admin/themes'
        this.meta.name = 'Theme'
        break;
      default:
        // code block
    }
     let { data } = await this.axios.get(this.meta.url,{
      loadingMesssage:"Getting Extension"
    })
    if(!data.data){
      alert('no data found')
    }
    data = data.data
    this.name = data.name
    this.category = data.category
    this.availableTo = data.available_to
    this.description = data.description
    this.mazuProduct = data.mazu_product
    this.extensionImage = data.image
  },
   data(){
    return {
      meta:{
         url:'',
        successUrl:'',
        name:""
      },
        name :"",
        availableTo:'rent',
        category:'',
        mazuProduct :'flirttool',
        description: "",
        extensionImage:null,
        updtaedExtenImage:null,
        categories:categories
    }
  },
  validations () {
    // const rentPriceRequired = () => true 
    return {
      name: { required },
      mazuProduct: { required },
      description: { required ,minLength:minLength(12)},
    }
  },
  methods:{
    async addExtension() {
      const isFormCorrect = await this.v$.$validate()
      console.log(isFormCorrect)
      if(!isFormCorrect) return
      var formData = new FormData();
      formData.append('name', this.name);
      formData.append('category', this.category);
      formData.append('mazu_product', this.mazuProduct);
      formData.append('available_to', this.availableTo);
      formData.append('description', this.description);
      if(this.updtaedExtenImage){
        formData.append('files', this.updtaedExtenImage,'files');
      }
      try{
        let { data } = await this.axios.put(this.meta.url,formData,{
          loadingMesssage:"Updating Extension .."
        })
        this.$notify({ title:"Message",type: "success", text: "Extension Added Successfully" });
        console.log(data)
        this.$router.push(this.meta.successUrl)
      }catch(e) {
        console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    },
    setNewExtensionImage(data){
      console.log(this)
      this.updtaedExtenImage = Object.values(data)[0][0]
    },
    addExtensionGallery(data){
      console.log(data)
      
      this.newGalleryImages = Object.values(data)[0]
      console.log(this)
    },
    removeGalleryImage(file){
      alert(file)
    }
  },
}
</script>

<style>
input:disabled {
  background: #dddddd;
  border:#dddddd;
}
.opaque{
  opacity: 0.1 !important;
}
</style>