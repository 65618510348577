
<template>
  <article class=" px-4 pt-16 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl  md:px-24 lg:px-8 ">
    
    <section class="hero container max-w-screen-lg mx-auto text-left pb-10 lg:px-96">
      <div class="">
        <img src="@/assets/images/dialogmakers.png" alt="screenshot" />
      </div>
    </section>

    <div class="px-56">
      <p class="text-left ">In 2020, a young start-up company from New Taipei, Taiwan arrives at a new way to feel
        online communication in communities.  With the creation of our own AI personality MAZU, we embrace a
        completely new way to enjoy community features – without losing the sense of reality and authenticity  to this
        world that we love so  much as if we could have our first date in a bar or café, practice for  the test for the
        driver licence at home, study for the next exam at the university, or sell  or buy stuff  around the world at the
        spur of the moment. Dialogmakers is a home for creative heads around the world. With our exclusive non-call
        center strategies,  we work with experienced independent voice and non-voice customer support professionals who
        think entrepreneurially  and act at  eye level with our customers</p>



      <p class="mt-5 text-left ">Tapping into new levels of online dating, DialogMakers ultimate creation  of AI
        personality MAZU helps to find your personal hyperlink with someone that is 95% your perfect match. For this,
        the MAZU AI system determines compatibility ratings  based on a number of parameters, including personal
        preferences in members’ profiles. Mazu was designed as a web-based community, integrated into the mobile data 
        world through various applications for andoid and ios.</p>


      <p class="mt-5 mb-24 text-left ">At this moment, MAZU™ is available for various projects as Online Dating, Market
        Places, Websites, Training systems, social media integrations, plugins and add-ons  which are all available now
        for all purposes.</p>
    </div>


  </article>
</template>