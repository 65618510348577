<style>
  /* Custom style */
  .header-right {
    width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
    width: 16rem;
  }
  @media only screen and (min-width: 768px) {
    .header-right {
      width: calc(100% - 16rem);
    }
  }
</style>
<template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full md:w-64 h-14 dark:bg-gray-800 border-none">
          <!-- <img class="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg" /> -->
          <span class="text-xl">Products Purchsed</span>
        </div>
        <!-- <div class="flex justify-between items-center h-14 bg-teal-800 dark:bg-gray-800 header-right">
          <div class="bg-white rounded flex items-center w-full max-w-xl mr-4 p-2 shadow-sm border border-gray-200">
            <button class="outline-none focus:outline-none">
              <svg class="w-5 text-gray-600 h-5 cursor-pointer" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
            <input type="search" name="" id="" placeholder="Search" class="w-full pl-3 text-sm text-black outline-none focus:outline-none bg-transparent" />
          </div>
          
          </div> -->
      </div>
      <!-- ./Sidebar -->

      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 md:ml-64">
        <!-- component -->
        <div class="flex items-center lg:-mt-10 px-5 justify-center p-12">
          <!-- Author: FormBold Team -->
          <div class="mx-auto mt-12 lg:w-4/5 bg-white font-sans">
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Extensions</h1>
            </div>
            <div v-if="extensions.length" class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
              <div class="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
                <a v-for="extension in extensions" :key="extension" href="/" aria-label="View Item">
                  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                    <img class="object-cover w-full h-56 md:h-64 xl:h-80" alt="" :src="getProductUrl(extension)" />
                    <div class="absolute inset-x-0 bottom-0 px-6 py-16 h-full bg-gradient-to-r from-blue-900 bg-opacity-75">
                      <p class="text-sm font-medium text-left lg:mt-24 py-6 tracking-wide text-white">{{extension.name}}</p>
                      <div class="grid grid-cols-3">
                         <div><a href="/dashboard/product-detail/695ec4ad-4a50-47c3-8ad6-6a7f87ee3466?productType=plugin" class="btn bg-orange-500 border-white text-xs text-white">View</a></div>
                        <div class="flex items-center">
                         <StarSvg :active="true" />
                          <StarSvg :active="true" />
                          <StarSvg :active="true" />
                          <StarSvg :active="true" />
                         <StarSvg/>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div v-else >
              <div class="mt-4">
                <h1 class="px-4 mb-5  text-red-500">No extension available</h1>
              </div>
            </div>
          </div>
          
        </div>
      </div>

       <div class="h-full ml-14 bg-teal-50 mb-10 md:ml-64">
        <!-- component -->
        <div class="flex items-center lg:-mt-10 px-5 justify-center p-12">
          <!-- Author: FormBold Team -->
          <div class="mx-auto  lg:w-4/5 bg-white font-sans">
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Plugins</h1>
            </div>
            <div  v-if="plugins.length" class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
              <div class="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
                <a v-for="plugin in plugins" :key="plugin" href="/" aria-label="View Item">
                  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                    <img class="object-cover w-full h-56 md:h-64 xl:h-80" alt="" :src="getProductUrl(plugin)" />
                    <div class="absolute inset-x-0 bottom-0 px-6 py-16 h-full bg-gradient-to-r from-blue-900 bg-opacity-75">
                      <p class="text-sm font-medium text-left lg:mt-24 py-6 tracking-wide text-white">{{plugin.name}}</p>
                      <div class="grid grid-cols-3">
                          <div><a href="/dashboard/product-detail/695ec4ad-4a50-47c3-8ad6-6a7f87ee3466?productType=plugin" class="btn bg-orange-500 border-white text-xs text-white">View</a></div>
                        <div class="flex items-center">
                         <StarSvg :active="true" />
                          <StarSvg :active="true" />
                          <StarSvg :active="true" />
                          <StarSvg :active="true" />
                         <StarSvg/>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
             <div v-else >
              <div class="mt-4">
                <h1 class="px-4 mb-5  text-red-500">No plugins available</h1>
              </div>
            </div>
          </div>
          
        </div>
      </div>

       <div class="h-full ml-14  bg-teal-50 mb-10 md:ml-64">
        <!-- component -->
        <div class="flex items-center lg:-mt-10 px-5 justify-center p-12">
          <!-- Author: FormBold Team -->
          <div class="mx-auto  lg:w-4/5 bg-white font-sans">
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Themes</h1>
            </div>
            <div v-if="themes.length" class="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
              <div class="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
                <a v-for="theme in themes" :key="theme" href="/" aria-label="View Item">
                  <div class="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                    <img class="object-cover w-full h-56 md:h-64 xl:h-80" alt="" :src="getProductUrl(theme)" />
                    <div class="absolute inset-x-0 bottom-0 px-6 py-16 h-full bg-gradient-to-r from-blue-900 bg-opacity-75">
                      <p class="text-sm font-medium text-left lg:mt-24 py-6 tracking-wide text-white">{{theme.name}}</p>
                      <div class="grid grid-cols-3">
                        <div><a href="/dashboard/product-detail/695ec4ad-4a50-47c3-8ad6-6a7f87ee3466?productType=plugin" class="btn bg-orange-500 border-white text-xs text-white">View</a></div>
                        <div class="flex items-center">
                         <StarSvg :active="true" />
                          <StarSvg :active="true" />
                          <StarSvg :active="true" />
                          <StarSvg :active="true" />
                         <StarSvg/>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div v-else >
             <div class="mt-4">
              <h1 class="px-4 mb-5  text-red-500">No themes available</h1>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarSvg from '@/components/small/StarSvg.vue'
import helperMixin from '@/mixins/helper';
export default {
   mixins:[helperMixin],
   components:{
    StarSvg
   },
   data() {
     return {
       extensions:[],
       themes:[],
       plugins:[],
    }
   },
   async created() {
    try {
      let { data } = await this.axios.get(`user/accounts/products/${this.$store.state.core.activeAccount.id}`)
     let allProducts = data.data
     this.extensions = allProducts.Extensions
     this.themes = allProducts.Themes
     this.plugins = allProducts.Plugins
   } catch(e) {
      let { data : { message } } = e.response
      this.error = message
      this.$notify({ title: "Error", text: this.error });
    }
   }
 }
</script>
