<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <!-- <img class="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg" /> -->
          <span class="  text-xl">MAZU Admin</span>
        </div>
      </div>
      <!-- ./Header -->
      <!-- ./Sidebar -->
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="grid gap-5 row-gap-8 lg:grid-cols-2">
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none">
                  Hello Back {{$store.state.core.userData.first_name}}
                </h2>
                <p class="text-base text-gray-700 md:text-sm">
                 This is the admin area
                </p>
              </div>
            </div>
            <div class="items-end justify-end lg:pl-96 ">
              <img
                class="object-cover w-full h-full rounded shadow-sm lg:h-36 lg:w-36 sm:h-49 sm:w-96 items-end justify-end"
                src="@/assets/images/login/Manufacturerlogo.jpg" alt="" />
            </div>
          </div>
        </div>
        <!-- start if login and register info -->
       
        
        <!-- end of login and regitser -->
      </div>
    </div>
  </div>
</template>