<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
       
      </div>
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 md:ml-64">
        <!-- component -->
        <div class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
       <div class="text-center shadow-xl mt-12 card lg:mx-48 xl:mx-48">
        <figure class="h-full px-10 pt-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-56 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style="width: 16.5rem;"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </figure>
        <div class="card-body">
          <h2 class="card-title">Payment Failed</h2>
          <p>
                    There was an issue processing your payment please try again.
          </p>
        </div>
      </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
