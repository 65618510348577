<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">MAZU versions</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  MAZU versions
                </h2>
             </div>
              <div class="max-w-xl mb-6">
               <router-link
                to="/admin/mazus/add"
                class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline bg-teal-500 border-0 font-bold text-uppercase focus:outline-none"
                aria-label="Sign up"
                title="Sign up"
                >
              Add Mazu version
               </router-link>
             </div>
             <input  v-model="searchValue"  style="width:23%;padding:4px;border:solid 1px gray;" placeholder="search" type="text" class="w-full  border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
             <br>
              <EasyDataTable
                buttons-pagination
                  border-cell
                  :alternating="true"
                  theme-color="red"
                  rows-per-page="5"
                  :headers="headers"
                  :items="items"
                  search-field="name"
                 :search-value="searchValue"
                >
                
                <template #item-operation="item">
                  <div class="operation-wrapper">
                    <router-link style="float:left" :to="{name: 'updateMazus', params: { id: item.id }}"><Icon icon="mdi-light:file-multiple" height="24" :inline="true"/></router-link>
                    <router-link style="float:left" :to="{name: 'mazuContent', params: { id: item.id }}"><Icon icon="fluent-mdl2:explore-content-single" height="24" :inline="true"/></router-link>
                     <router-link style="float:left" :to="{name: 'mazuPrices', params: { id: item.id }}"><Icon icon="ph:currency-circle-dollar-light" height="24" :inline="true"/></router-link>
                   </div>
                    <a style="float:left" href="#" @click.prevent="deleteItem(item)"><Icon icon="mdi-light:delete" height="24" :inline="true" /></a>

                </template>
                </EasyDataTable>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
export default {
  components:{
    Icon
  },
  data(){
    return {
      searchValue:"",
      headers:[
        { text: "Name", value: "name" ,width: 120 },
        { text: "Available To", value: "available_to", sortable: true,width: 120  },
        { text: "Description", value: "description", sortable: true ,width: 500},
        { text: "Operation", value: "operation" , width: 120 }
      ],
       items : []
    }
  },
  async mounted() {
    let { data } = await this.axios.get('admin/mazu',{
      loadingMesssage:"Getting Mazu versions"
    })
    this.items = data.data
    console.log(data)
  },
  methods:{
    async deleteItem(item) {
      let result = await this.$swal({
        title: "Delete this mazu version?",
        text: "Are you sure? You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!"
      })
      if(result.isConfirmed){
        try{
           await this.axios.delete(`admin/mazu/${item.id}`,{
            loadingMesssage:"Deleting Mazu version .."
          })
          this.items = this.items.filter(i => i.id != item.id)
        }catch(e){
          console.log(e)
          let { data : { message } } = e.response
          this.error = message
          this.$notify({ title: "Error", text: this.error });
        }
       
      }
      console.log(result)
      console.log(item)

    }
  }
}
</script>