import env from '../env'
const helperMixin = {
  data() {
    return {
      baseUrl:env.production ? 'https://mazutech.online/' : "http://localhost:8082/"
    }
  },
  methods: {
    getUrl(path){
      return `${this.baseUrl}${path}`
    },
    getProductUrl(product) {
      if(product.extension_image) {
        return this.getUrl(product.extension_image)
      }else if(product.theme_image) {
        return this.getUrl(product.theme_image)
      }else if(product.plugin_image) {
        return this.getUrl(product.plugin_image)
      }
    },
   async selectAccount(id) {
      let { data } = await this.axios.put(`user/accounts/switch/${id}`,{
        loadingMesssage:"Switching Account .."
      })
     let activeAccount = data.account
     this.$store.commit("activeAccount",activeAccount)
    },
    async refreshUserData() {
      let { data } = await this.axios.post('user/data',{
        loadingMesssage:"Please wait.."
      })
      this.logOutUser()
      this.$store.commit("userData",data.user)
      this.$store.commit("userAccounts",data.accounts)
      this.$store.commit("activeAccount",data.activeAccount)
      this.$store.commit("token",data.token)
      this.$store.commit("adminloggedIn",false)
      this.$store.commit("loggedIn",true)

    },
    logOutUser() {
      this.$store.commit("userData",{})
      this.$store.commit("userAccounts",[])
      this.$store.commit("activeAccount",{})
      this.$store.commit("loggedIn",false)
      this.$store.commit("token",null)
    }
  }
}

export default helperMixin