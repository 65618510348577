<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <!-- component -->
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <!-- Header -->
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <!-- <img class="w-7 h-7 md:w-10 md:h-10 mr-2 rounded-md overflow-hidden" src="https://therminic2018.eu/wp-content/uploads/2018/07/dummy-avatar.jpg" /> -->
          <span class="  text-xl">Join Mazu Dating Community</span>
        </div>
        <!-- <div class="flex justify-between items-center h-14 bg-teal-800 dark:bg-gray-800 header-right">
          <div class="bg-white rounded flex items-center w-full max-w-xl mr-4 p-2 shadow-sm border border-gray-200">
            <button class="outline-none focus:outline-none">
              <svg class="w-5 text-gray-600 h-5 cursor-pointer" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
            <input type="search" name="" id="" placeholder="Search" class="w-full pl-3 text-sm text-black outline-none focus:outline-none bg-transparent" />
          </div>
          
          </div> -->
      </div>
      <!-- ./Sidebar -->
      
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 md:ml-64">
        <!-- component -->
        <div class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <!-- Author: FormBold Team -->
          <div class="mx-auto mt-12  lg:w-4/5  bg-white font-sans" >
          <div class="mt-12">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Join Mazu Dating Community</h1>
            </div>
            <div class="w-full mt-4 px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Name
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="Community Name"
                  v-model="form.name"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
              </div>
              <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
                <span v-if="v$.form.name.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Description
                </label>
                <textarea
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="Community Description"
                  v-model="form.description"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  ></textarea>
              </div>
              <div class="input-errors" v-for="error of v$.form.description.$errors" :key="error.$uid">
                <span v-if="v$.form.description.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div class="w-full mt-4 px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Domain
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="Community Name"
                  v-model="form.domain"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
              </div>
              <div class="input-errors" v-for="error of v$.form.domain.$errors" :key="error.$uid">
                <span v-if="v$.form.domain.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div class="w-full mt-4 px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Nameserver 1
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="name server1"
                  v-model="form.nameserver1"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
              </div>
              <div class="input-errors" v-for="error of v$.form.nameserver1.$errors" :key="error.$uid">
                <span v-if="v$.form.nameserver1.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
            <div class="w-full mt-4 px-3 sm:w-1/2">
              <div class="mb-5 ">
                <label
                  for="fName"
                  class="mb-3 block text-base font-medium text-gray-700 font-sans"
                  >
                Namrserver 2
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="nameserver2"
                  v-model="form.nameserver2"
                  class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
              </div>
              <div class="input-errors" v-for="error of v$.form.nameserver2.$errors" :key="error.$uid">
                <span v-if="v$.form.nameserver2.$error" class="form-error">{{ error.$message }}</span>
              </div>
            </div>
             <div class="mb-4">
                <button
                  class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                    @click="joinDatingCommunity"
                  >
                Submit
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { useVuelidate } from '@vuelidate/core'
  import { required , minLength } from '@vuelidate/validators'
   export default {
     setup () {
       return { v$: useVuelidate() }
     },
     validations () {
     return {
       form:{
           name: { required },
           description: { required },
           domain: { required },
           nameserver1: { required },
           nameserver2: { required },
         }
      }
   },
     data() {
       return {
         form:{
          name:"",
          description:"",
          domain:"",
          nameserver1:"",
          nameserver2:""
         }
       }
     },
     methods:{
       async joinDatingCommunity() {
         console.log(this.form)
           const isFormCorrect = await this.v$.$validate()
           if(!isFormCorrect) {
              return this.$notify({ title:"Error",type: "error", text: "Please fillout all the required fields" }); 
           }
           let formData = { ... this.form , accountId:this.$store.state.core.activeAccount.id }
           try{
            let { data } = await this.axios.post(`user/dating-community`,formData,{
             loadingMesssage:"Loading .."
           })
           this.$notify({ title:"Message",type: "success", text: "Welcome to mazu dating community" });
           this.$router.push(`/dashboard/`)
         }catch(e) {
           console.log(e)
           let { data : { message } } = e.response
           this.error = message
           this.$notify({ type:"error",title: "Error", text: this.error });
         }
       }
     }
   }
</script>