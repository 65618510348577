<template>
  <UpdatePrices
  productType="plugin"
  />
</template>

<script>
import UpdatePrices from '@/components/Admin/UpdatePrices.vue'
export default {
  components:{
    UpdatePrices
  }
}
</script>