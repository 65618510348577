<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">Dating Communities</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  Dating Communities
                </h2>
             </div>
             <input  v-model="searchValue"  style="width:23%;padding:4px;border:solid 1px gray;" placeholder="search" type="text" class="w-full  border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
             <br>
              <EasyDataTable
                buttons-pagination
                  border-cell
                  :alternating="true"
                  theme-color="red"
                  rows-per-page="5"
                  :headers="headers"
                  :items="items"
                  search-field="name"
                 :search-value="searchValue"
                >

                <template #item-status="item">
                  <span v-if="item.status" style="color:green"> Running...</span>
                  <span v-else style="color:red"> Not Running </span>
                </template>
                
                <template #item-operation="item">
                  <div class="operation-wrapper">
                    <router-link style="float:left" :to="{name: 'DatingCommunitysetting', params: { id: item.id }}"><Icon icon="mdi-light:file-multiple" height="24" :inline="true"/></router-link>
                    <!-- <router-link style="float:left" :to="{name: 'mazuContent', params: { id: item.id }}"><Icon icon="fluent-mdl2:explore-content-single" height="24" :inline="true"/></router-link> -->
                     <router-link style="float:left" :to="{name: 'UpdateDburl', params: { id: item.id }}"><Icon icon="mdi:database-arrow-right-outline" height="24" :inline="true"/></router-link>
                      <a href="#"
                        @click="toggleAppStatus(item)"
                         style="float:left;">
                        <Icon style="color:green" v-if="item.status" icon="ri:toggle-line" height="24" :inline="true"/>
                        <Icon style="color:red" v-else icon="mdi:toggle-switch-outline" height="24" :inline="true"/>
                      </a>
                   </div>
                </template>
                </EasyDataTable>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
export default {
  components:{
    Icon
  },
  data(){
    return {
      searchValue:"",
      headers:[
        { text: "Name", value: "name" ,width: 120 },
        { text: "Domain", value: "domain", sortable: true,width: 120  },
        { text: "Username", value: "User.username", sortable: true,width: 120  },
        { text: "Account name", value: "MazuAccount.account_name", sortable: true,width: 120  },
        { text: "DB", value: "database_url",width: 400  },
        { text: "Status", value: "status", sortable: true,width: 120  },
        { text: "Operation", value: "operation" , width: 200 }
      ],
       items : []
    }
  },
  async mounted() {
    await this.fetchCommunities()
  },
  methods:{
    async toggleAppStatus(app) {
      try {
        await this.axios.patch(`admin/backend/dating-community/status/${app.id}`,{
          loadingMesssage:"Updating"
        })
        await this.fetchCommunities()
      }catch(e) {
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    },
    async fetchCommunities() {
      let { data } = await this.axios.get('admin/backend/dating-communities',{
          loadingMesssage:"Getting Mazu versions"
      })
      this.items = data.datingCommunities
      console.log(data)
    }
  }
}
</script>