<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">  {{meta.name}} Prices</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                   {{meta.name}} Prices
                </h2>
             </div>
               <div class="">
                <!-- component -->
                <div v-for="(price,index) in prices" :key="index" class="flex items-center lg:-mt-10  px-5 justify-center p-12" >
                 <div class="mx-auto  lg:w-4/5  bg-white font-sans" >
                   <div class=" px-5 mt-12 mb-12" >
                     <div class="mb-3 block text-base font-medium text-teal-500 font-sans">Price in {{ price.currency.toLocaleUpperCase()}}</div>
                      <div class="-mx-3 flex flex-wrap">
                        <div class=" px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             Buying Price
                            </label>
                            <input
                              type="number"
                              v-model="prices[index].buyingPrice"
                              placeholder ="Buying Price"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                          </div>
                        </div>
                        <div class=" px-3 sm:w-4/2">
                          <div class="mt-8">
                            <button 
                            @click="updtaeProductPrice(price,'buy')"
                            class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"> Update/Add </button>
                          </div>
                        </div>
                      </div>
                       <div class="-mx-3 flex flex-wrap">
                        <div class=" px-3 sm:w-4/2">
                          <div class="mb-5 ">
                            <label
                              for="fName"
                              class="mb-3 block text-base font-medium text-teal-500 font-sans"
                              >
                             Rent Price
                            </label>
                            <input
                              type="number"
                              v-model="prices[index].rentPrice"
                              placeholder ="Rent Price"
                              class="w-full rounded-md border border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                              />
                          </div>
                        </div>
                        <div class=" px-3 sm:w-4/2">
                          <div class="mt-8">
                            <button
                            @click="updtaeProductPrice(price,'rent')"
                             class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"> Update/Add </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props:['productType'],
  async created() {
    switch(this.productType) {
      case 'extension':
        this.meta.url = `admin/extension/${this.$route.params.id}`
        this.meta.putUrl = `admin/extension/price/${this.$route.params.id}`
        this.meta.successUrl = '/admin/extensions'
        this.meta.name = 'Extension'
        break;
      case 'plugin':
        this.meta.url = `admin/plugin/${this.$route.params.id}`
         this.meta.putUrl = `admin/plugin/price/${this.$route.params.id}`
        this.meta.successUrl = '/admin/plugins'
        this.meta.name = 'Plugin'
        break;
      case 'theme':
        this.meta.url = `admin/theme/${this.$route.params.id}`
         this.meta.putUrl = `admin/theme/price/${this.$route.params.id}`
        this.meta.successUrl = '/admin/themes'
        this.meta.name = 'Theme'
        break;
      case 'mazu':
        this.meta.url = `admin/mazu/${this.$route.params.id}`
         this.meta.putUrl = `admin/mazu/price/${this.$route.params.id}`
        this.meta.successUrl = '/admin/mazus'
        this.meta.name = 'Mazu version'
        break;
      default:
    }
    let { data } = await this.axios.get(this.meta.url,{
      loadingMesssage:"Getting Extension"
    })
    this.setProductPrices(data.data)
    if(!data.data){
      alert('no data found')
    }
  },
  data() { 
    return {
      meta:{
        url:'',
        successUrl:'',
        name:''
      },
      prices:[
        {
          rentPrice :null,
          buyingPrice :null,
          currency:'usd',
        },
        {
          rentPrice :null,
          buyingPrice :null,
          currency:'eur',
        },
        {
          rentPrice :null,
          buyingPrice :null,
          currency:'gbp',
        },
      ]
    }
  },
  methods: {
    setProductPrices(data) {
      let prices = data.Prices
      let currencies = Object.keys(prices)
      currencies.forEach((currency,index)=>{
        let currencyPrices = Object.values(prices)[index]
        let pricesIndex = this.prices.findIndex(p => p.currency == currency)
        let rentPrice = currencyPrices.find(p => p.purchaseType == 'rent')
        let buyingPrice = currencyPrices.find(p => p.purchaseType == 'buy')
        this.prices[pricesIndex].rentPrice = rentPrice?.price
        this.prices[pricesIndex].buyingPrice = buyingPrice?.price
      })
    },
    async updtaeProductPrice(price,purchaseType) {
      console.log(price)
      console.log(purchaseType)
      let { data } = await this.axios.put(this.meta.putUrl,{
        purchaseType:purchaseType,
        price:purchaseType == 'rent' ? price.rentPrice : price.buyingPrice,
        currency:price.currency
      },{
         loadingMesssage:"Updating"
      })
    }
  }
}
</script>