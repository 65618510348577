<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">MAZU Users</span>
        </div>
      </div>
   |   <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
         
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                  Users
                </h2>
             </div>
             <input  v-model="searchValue"  style="width:23%;padding:4px;border:solid 1px gray;" placeholder="search" type="text" class="w-full  border-teal-500 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md">
             <br>
              <EasyDataTable
                buttons-pagination
                  border-cell
                  :alternating="true"
                  theme-color="red"
                  rows-per-page="5"
                  :headers="headers"
                  :items="items"
                  search-field="username"
                 :search-value="searchValue"
                >
                
              
                </EasyDataTable>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
export default {
  components:{
    Icon
  },
  data(){
    return {
      searchValue:"",
      headers:[
        { text: "Username", value: "username" },
        { text: "First Name", value: "first_name", sortable: true },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "Email", value: "email", sortable: true },
      ],
       items : []
    }
  },
  async mounted() {
    let { data } = await this.axios.get('admin/backend/users',{
      loadingMesssage:"Getting Users"
    })
    this.items = data.data
    console.log(data)
  },
  methods:{
    
  }
}
</script>