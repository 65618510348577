<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">MAZU Account</span>
        </div>
      </div>
<div class="h-full ml-14 mt-14 bg-teal-50  md:ml-64">
    
    <div class="border-b border-gray-200 dark:border-gray-700 mb-4 p-12">
        <ul class="flex flex-wrap -mb-px" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
            <li class="mr-2" role="presentation">
                <button @click="setActiveTab('community_details')" class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-4 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300" role="tab"  :class="{'active':active == 'community_details' }">Community Details</button>
            </li>
            <li class="mr-2" role="presentation">
                <button @click="setActiveTab('payment_methods')" class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-4 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 " role="tab"  :class="{'active':active == 'payment_methods' }">Payment Methods</button>
            </li>
            <li class="mr-2" role="presentation">
                <button @click="setActiveTab('theme_settings')" class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-4 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300" role="tab"  :class="{'active':active == 't3' }">Theme Settings</button>
            </li>
            <li role="presentation">
                <button @click="setActiveTab('t4')" class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-4 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300" role="tab"  :class="{'active':active == 't4' }">Others</button>
            </li>
        </ul>
    </div>
    <div id="myTabContent">
        <div v-if="active == 'community_details'" class="bg-gray-50 p-4 rounded-lg dark:bg-gray-800 " id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <CommunityDetails/>
        </div>
        <div v-if="active == 'payment_methods'" class="bg-gray-50 p-4 rounded-lg dark:bg-gray-800 " id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
          <PaymentMethods/>
        </div>
        <div v-if="active == 'theme_settings'" class="bg-gray-50 p-4 rounded-lg dark:bg-gray-800 " id="settings" role="tabpanel" aria-labelledby="settings-tab">
            <ThemeSettings/>
        </div>
        <div v-if="active == 't4'" class="bg-gray-50 p-4 rounded-lg dark:bg-gray-800 " id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
            <OtherSettings/>
        </div>
    </div>

    
</div>

      
    </div>
  </div>
</template>
<script>
import CommunityDetails from '@/components/Dashboard/communitySettings/CommunityDetails.vue'
import PaymentMethods from '@/components/Dashboard/communitySettings/PaymentMethods.vue'
import ThemeSettings from '@/components/Dashboard/communitySettings/ThemeSetting.vue'
import OtherSettings from '@/components/Dashboard/communitySettings/OtherSettings.vue'

export default {
  components:{
    CommunityDetails,
    PaymentMethods,
    ThemeSettings,
    OtherSettings
  },
  data() {
    return {
      active:'community_details'
    }
  },
  methods:{
    setActiveTab(tab) {
      this.active = tab
    }
  }
}
</script>