<template>
  <MazushopComponent
   :loggedIn="false"
   :activeProducts="[]"
  />
</template>

<script>
import MazushopComponent from '@/components/Shop/MazushopComponent.vue'
export default {
  components:{
    MazushopComponent
  }
}
</script>