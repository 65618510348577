<style>
  /* Custom style */
  .header-right {
  width: calc(100% - 3.5rem);
  }
  .sidebar:hover {
  width: 16rem;
  }
  @media only screen and (min-width: 768px) {
  .header-right {
  width: calc(100% - 16rem);
  }
  }
</style>
<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">MAZU Account</span>
        </div>
      </div>
      <!-- ./Sidebar -->
      <div class="h-full ml-14 mt-14 bg-teal-50 mb-10 lg:-mt-8 md:ml-64">
        <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div class="grid gap-5 row-gap-8 lg:grid-cols-2">
            <div class="flex flex-col justify-top">
              <div class="max-w-xl mb-6">
                <h2
                  class="max-w-lg mb-6 font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none">
                  Hello Back {{$store.state.core.userData.first_name}}
                </h2>
                <p class="text-base text-gray-700 md:text-sm">
                  Description Goes here Description Goes here
                  Description Goes here Description Goes hereDescription Goes
                  here Description Goes here Description Goes here Goes
                  hereDescription Goes here Description Goes here Description
                  Goes here
                </p>
              </div>
            </div>
            <div class="items-end justify-end lg:pl-96 ">
              <img
                class="object-cover w-full h-full rounded shadow-sm lg:h-36 lg:w-36 sm:h-49 sm:w-96 items-end justify-end"
                src="@/assets/images/login/Manufacturerlogo.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="px-10 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-24 lg:py-5">
          <div class="p-8 rounded shadow-xl sm:p-16 bg-white">
            <div class="flex flex-col lg:flex-row">
              <div class="lg:w-24">
                <img src="@/assets/images/login/Shopownerslogo.jpg" alt="">
              </div>
              <div class="mb-6 lg:mb-0 px-5 lg:w-full lg:pr-5">
                <h2 class="font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none">
                  For mazu users
                </h2>
                <p class="mb-4 text-sm py-5 text-gray-700">
                  Manage your mazu version and use the support for your extensions and MAZU itself.
                </p>
                <!-- button -->
                <div class="grid  w-full">
                  <div v-if="!communityData" class="">
                    <button
                      @click="joinCommunity"
                      class="btn border-teal-500 text-gray-800 hover:bg-teal-300  hover:text-white  hover:border-white">Join mazu dating community
                    </button>
                  </div>
                  <div v-else class=""> <router-link to="/dashboard/viewdating"
                    class="btn border-teal-500 text-gray-800 hover:bg-teal-300 hover:text-white  hover:border-white ">View dating community settings</router-link>
                  </div>
                </div>
                <!-- button -->
              </div>
            </div>
          </div>
        </div>
        <div class="px-10 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-24 lg:py-5">
          <div class="p-8 rounded shadow-xl sm:p-16 bg-white">
            <div class="flex flex-col lg:flex-row">
              <div class="lg:w-24">
                <img src="@/assets/images/login/Manufacturerlogo.jpg" alt="">
              </div>
              <div class="mb-6 lg:mb-0 px-5 lg:w-full lg:pr-5">
                <h2 class="font-sans text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none">
                  Become a Manufacturer for extensions
                </h2>
                <p class="mb-4 text-sm py-5 text-gray-700">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque rem aperiam,
                  eaque ipsa quae. Sed ut perspiciatis unde omnis iste. Sed ut perspiciatis unde omnis iste error sit
                  voluptatem accusantium
                  doloremque rem aperiam.
                </p>
                <!-- button -->
                <div class="grid grid-cols-2 gap-4 w-56">
                  <div class=""><button
                    class="btn border-teal-500 text-gray-800 hover:bg-teal-300  hover:text-white  hover:border-white">Regitser</button>
                  </div>
                  <div class=""> <button
                    class="btn border-teal-500 text-gray-800 hover:bg-teal-300 hover:text-white  hover:border-white ">Regitser</button>
                  </div>
                </div>
                <!-- button -->
              </div>
            </div>
          </div>
        </div>
        <!-- end of login and regitser -->
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        communityData:null
      }
    },
    async created() {
      //$store.state.core.loggedIn
      if(!this.$store.state.core.activeAccount) return 
      this.setCommunityData()
    },
    methods:{
      async setCommunityData() {
        this.communityData = null
      let activeAccountId = this.$store.state.core.activeAccount.id
        let { data } = await this.axios.get(`user/dating-community/${activeAccountId}`, {
          loadingMesssage: "Loading"
        })
        if(data.data){
          this.communityData = data.data 
        }
        console.log(data.data,"datadata")
      },
      joinCommunity() {
       let activeAccount = this.$store.state.core.activeAccount
       if(activeAccount.is_paid) {
         this.$router.push('/dashboard/joindating')
       }else{
         this.$router.push(`dashboard/payment/${activeAccount.id}`)
       }
      }
    },
    watch:{
      "$store.state.core.activeAccount": {
        handler : function(){
          this.setCommunityData()
        }
      }
    }
  }
</script>