<template>
  <div x-data="setup()" class="">
    <div
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-teal-50 dark:bg-gray-700 text-black dark:text-white">
      <div class="fixed w-full mt- flex items-center justify-between h-14 text-white z-10">
        <div
          class="flex items-center text-center justify-start bg-black md:justify-center pl-3 w-full  md:w-64 h-14  dark:bg-gray-800 border-none">
          <span class="  text-xl">MAZU Account</span>
        </div>
      </div>

       <div v-if="!acknowledged" class="h-full mt-14 ml-14 bg-teal-50  md:ml-64">
        <div class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <div class="mx-auto pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Acknowledged</h1>
            </div>
            <div class="px-3">
            <p class="text-sm p text-red-700"><i>A new account has been registered with MAZU Community, Please click below to Acknowledge user about the ongoing Onboarding process.</i></p>
            <button
            @click="acknowledgeUser"
            class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 mt-4 px-8 text-center text-base font-semibold text-white outline-none"
            >Acknowledge User</button>
            </div>
          </div>
        </div>
      </div>


      <div class="h-full ml-14  bg-teal-50  md:ml-64">
        <!-- component -->
        <div class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <!-- Author: FormBold Team -->
          <div class="mx-auto mt-12  lg:w-4/5  bg-white font-sans" >
            <div class="mt-12">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Dating Community</h1>
            </div>
            <div class="mb-4 ml-2">
              <div class="px-3">
              <p class="text-sm p text-teal-700">Name : <b class="text-red-700">{{ name }} </b></p>
              <p class="text-sm p text-teal-700 mt-2">Description : <b class="text-red-700">{{ description }} </b></p>
          </div>
            </div>
          </div>
        </div>
      </div>

       <div class="h-full ml-14 bg-teal-50  md:ml-64">
        <div class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <div class="mx-auto pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Community Status</h1>
            </div>
            <div class="px-3">
            <p class="text-sm p text-red-700"> 
              <i v-if="status"> Running Successfully </i>
              <i v-else> Currently Stopped / not running </i>
             </p>
            </div>
          </div>
        </div>
      </div>

      <div class="h-full ml-14  bg-teal-50  md:ml-64">
        <div class="flex items-center  lg:-mt-10  px-5 justify-center p-12" >
          <div class="mx-auto pb-4  lg:w-4/5  bg-white font-sans" >
            <div class="mt-4">
              <h1 class="px-4 mb-5 font-bold font-sans text-teal-500">Community Domain Info </h1>
            </div>
            <div class="px-3">
              <p class="text-sm p text-teal-700">Domain : <b class="text-red-700">{{ domain }} </b></p>
              <p class="text-sm p text-teal-700 mt-2">Nameserver1 : <b class="text-red-700">{{ nameserver1 }} </b></p>
              <p class="text-sm p text-teal-700 mt-2">Nameserver2 : <b class="text-red-700">{{ nameserver2 }} </b></p>
          </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
  export default {
   data() {
    return {
      name:"",
      description:"",
      domain:"",
      nameserver1:"",
      nameserver2:"",
      acknowledged:false,
      status:false
    }
  },
  methods: {
   async acknowledgeUser() {
      try{
        await this.axios.patch(`admin/backend/dating-community/acknowledge/${this.$route.params.id}`, {
          loadingMesssage: "Loading"
        })
        this.$notify({ title:"Message",type: "success", text: "User Acknowledged" });
        this.acknowledged = true
      }catch(e){
         console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    }
  },
  async created() {
    try{
        let { data } = await this.axios.get(`admin/backend/dating-community/${this.$route.params.id}`, {
          loadingMesssage: "Loading"
        })
        let communityData = data.datingCommunity
        this.name = communityData.name
        this.description = communityData.description
        this.domain = communityData.domain
        this.nameserver1 = communityData.nameserver1
        this.nameserver2 = communityData.nameserver2
        this.acknowledged = communityData.acknowledged
        this.status = communityData.status
      }catch(e){
         console.log(e)
        let { data : { message } } = e.response
        this.error = message
        this.$notify({ title: "Error", text: this.error });
      }
    }
  }
</script>