<template>
<div class="loader-wrapper" v-if="$store.state.core.loading">
  <div class="loader">{{ text }}</div>
</div>
</template>



<script>
export default {
	props:['text']
}
</script>
<style>
  .loader-wrapper{
		height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999;
    opacity: 0.7;
     top: 0;
		left: 0; 
    background: black;
    transition: 0.5s;

	}
  .loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
  letter-spacing: 0.2em;
	z-index:99999
  }
  .loader::before, .loader::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background:white;
  position: absolute;
  animation: load 0.7s infinite alternate ease-in-out;
  }
  .loader::before {
  top: 0;
  }
  .loader::after {
  bottom: 0;
  }
  @keyframes load {
  0% {
  left: 0;
  height: 30px;
  width: 15px;
  }
  50% {
  height: 8px;
  width: 40px;
  }
  100% {
  left: 235px;
  height: 30px;
  width: 15px;
  }
  }
</style>